import React from 'react'
import { CircularProgress, Box } from '@mui/material'

const PreLoader = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
      <CircularProgress />
    </Box>
  )
}

export default PreLoader
