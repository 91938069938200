import { axiosBaseQuery } from '../../config/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IGetSODTypes, IPostSODTypes } from './sodApi.types'

interface ISODPermissionParams {
  search: string
}

export const sodApi = createApi({
  reducerPath: 'sodApi',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 0,
  tagTypes: ['sodPermissions'],
  endpoints: (builder) => ({
    getSodPermission: builder.query<IGetSODTypes, ISODPermissionParams>({
      query({ search }) {
        return {
          url: `/api/v1/sod${search ? '?search=' + search : ''}`,
          method: 'get',
        }
      },
      providesTags: ['sodPermissions'],
    }),
    updateSodPermissions: builder.mutation<IPostSODTypes, any>({
      query: (payload) => ({
        url: '/api/v1/sod/',
        method: 'post',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['sodPermissions'],
    }),
    sodRolesCheck: builder.mutation<IPostSODTypes, any>({
      query: (payload) => ({
        url: '/api/v1/users/requests/check-roles/',
        method: 'post',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
  }),
})

export const { useUpdateSodPermissionsMutation, useGetSodPermissionQuery, useSodRolesCheckMutation } = sodApi
