import JWTDecode from 'jwt-decode'

const tokenName = '@sterling_core_token'

const setToken = (token: string) => localStorage.setItem(tokenName, token)

const getToken = () => localStorage.getItem(tokenName) || ''

const getDecodedJwt = () => {
  const token = getToken()

  if (token) {
    return JWTDecode(token)
  }

  return {}
}

const removeToken = () => {
  localStorage.removeItem(tokenName)
}

const isTokenValid = () => {
  const { exp, first_time_login, force_change_password }: any = tokenUserData()
  const currentTime = Date.now() / 1000

  return exp > currentTime && !first_time_login && !force_change_password
}
const tokenActive = () => {
  const { exp }: any = tokenUserData()
  const currentTime = Date.now() / 1000

  return exp > currentTime
}

const tokenUserData = () => {
  const decodedToken = getDecodedJwt()

  return decodedToken
}

const Auth = {
  isTokenValid,
  getDecodedJwt,
  setToken,
  getToken,
  removeToken,
  tokenUserData,
  tokenActive,
}

export default Auth
