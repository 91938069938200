import { userAndAccessMgtPaths, productFactoryPaths, paymentPaths, customerMgtPaths, loanPaths } from '@app/routes/path'
import { configEnginePaths } from '@app/routes/path'
import {
  AccessManagementIcon,
  ConfigEngineIcon,
  CustomerManagementIcon,
  DashboardIcon,
  LoanManagementIcon,
  PaymentModuleIcon,
  ProductFactoryIcon,
} from '../components/icons'

export interface ISidebarItem {
  slug: string
  title: string
  path: string
  icon: (active: boolean) => JSX.Element
}

export const SIDEBAR_ITEMS: ISidebarItem[] = [
  {
    slug: 'home',
    title: 'Home',
    path: configEnginePaths.HOME,
    icon: (active: boolean) => DashboardIcon({ active }),
  },
  {
    slug: 'config-engine',
    title: 'Config. Engine',
    path: configEnginePaths.CONFIG_ENGINE,
    icon: (active: boolean) => ConfigEngineIcon({ active }),
  },

  {
    slug: 'access-management',
    title: 'User & Role Mgt.',
    path: userAndAccessMgtPaths.USER_AND_ACCESS_MANAGEMENT,
    icon: (active: boolean) => AccessManagementIcon({ active }),
  },

  {
    slug: 'product-factory',
    title: 'Product Factory',
    path: productFactoryPaths.PRODUCT_FACTORY,
    icon: (active: boolean) => ProductFactoryIcon({ active }),
  },
  {
    slug: 'customer-management',
    title: 'Customer Mgt.',
    path: customerMgtPaths.CUSTOMER_MANAGEMENT,
    icon: (active: boolean) => CustomerManagementIcon({ active }),
  },
  {
    slug: 'payement-module',
    title: 'Payment',
    path: paymentPaths.PAYMENT,
    icon: (active: boolean) => PaymentModuleIcon({ active }),
  },
  
  {
    slug: 'loan-management',
    title: 'Loan',
    path: loanPaths.LOAN,
    icon: (active: boolean) => LoanManagementIcon({ active }),
  },
]
