import { pxToRem } from '@app/theme/util'
import { styled, Paper, PaperProps } from '@mui/material'

export interface IStyledPaperProps extends PaperProps {
  available?: boolean
  disabled?: boolean
}
export const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'available' && prop !== 'disabled',
})<IStyledPaperProps>(({ theme, available, disabled }) => ({
  padding: pxToRem(24),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  cursor: available ? 'pointer' : 'not-allowed',
  opacity: available ? 1 : 0.65,
  border: '1px solid #E5E9EB',
  borderRadius: '8px',
  '&:hover': {
    boxShadow: disabled ? '' : '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
}))
