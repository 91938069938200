import { Navigate } from 'react-router-dom'

import Auth from '@app/utils/auth'

interface IAuthGaurdProps {
  children: JSX.Element
}

const AuthGaurd = ({ children }: IAuthGaurdProps) => {
  const isAuthenticated = Auth.isTokenValid()

  if (isAuthenticated) {
    return children
  }

  return <Navigate to='/login' state={{ expired: true }} />
}

export default AuthGaurd
