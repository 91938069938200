import { pxToRem } from '@app/theme/util'
import { Badge, Box, Button, Grid, Link, Paper, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { StyledBreadcrumbs, StyledContentWrapper } from './contentLayout.styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import CustomSearch from '@app/components/Search'
import NotificationsIcon from '@mui/icons-material/Notifications'

export interface IContentProp {
  title: string
  children: JSX.Element
  usePrimaryButton?: boolean
  primaryButtonIcon?: () => JSX.Element
  primaryButtonText?: string
  onPrimaryButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  breadcrumbList?: any[]
  currentPageTitle?: string
  size?: 'sm' | 'lg'
  tabComponent?: JSX.Element
  onSearch?: (value: string) => void
  searchPlaceholder?: string
  hideSearch?: boolean
  paperStyle?: any
  contentPosition?: string
  isHome?: boolean
}

export default function ContentLayout({
  title,
  children,
  usePrimaryButton = false,
  primaryButtonIcon,
  primaryButtonText,
  onPrimaryButtonClick,
  breadcrumbList,
  currentPageTitle,
  size = 'sm',
  tabComponent,
  onSearch,
  searchPlaceholder,
  hideSearch,
  paperStyle = {},
  contentPosition,
  isHome,
}: IContentProp) {
  const navigate = useNavigate()
  return (
    <StyledContentWrapper className='hello'>
      <Paper
        elevation={0}
        sx={{
          p: pxToRem(32),
          pb: !!tabComponent ? 0 : undefined,
          position: 'fixed',
          right: 0,
          left: '65px',
          zIndex: 2,
          ...paperStyle,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center'>
              <Typography component='h1' fontWeight={700} fontSize={pxToRem(38)} letterSpacing='-0.022em' color='#636363'>
                {title}
              </Typography>
              {usePrimaryButton && (
                <Button
                  variant='contained'
                  sx={{ marginLeft: pxToRem(36) }}
                  startIcon={primaryButtonIcon!()}
                  onClick={(e) => onPrimaryButtonClick!(e)}
                >
                  {primaryButtonText}
                </Button>
              )}
            </Box>
            {isHome && (
              <Stack direction='row' spacing={1} mt={2}>
                <Badge badgeContent={10} color='error' max={9} showZero>
                  <NotificationsIcon sx={{ fontSize: '18px' }} />
                </Badge>
                &nbsp;
                <Typography variant='subtitle1' color='text.secondary' component='span' letterSpacing={0.6}>
                  You have 10 notifications!
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
        {size === 'lg' && <Toolbar sx={{ minHeight: '50px !important' }} />}

        {breadcrumbList && size === 'sm' && (
          <Stack direction='row' spacing={1} mt={1}>
            {currentPageTitle && <KeyboardBackspaceIcon color='inherit' onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} />}
            <StyledBreadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
              {breadcrumbList?.map((stack) => (
                <Link component={RouterLink} to={stack.to} underline='none' key={stack.title} textTransform='uppercase'>
                  {stack.title}
                </Link>
              ))}
              <Typography className='title' color='text.secondary' textTransform='uppercase'>
                {currentPageTitle}
              </Typography>
            </StyledBreadcrumbs>
          </Stack>
        )}

        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={4} justifyContent={tabComponent ? 'space-between' : 'flex-end'}>
          {tabComponent}

          {!hideSearch && (
            <Box width={{ xs: '100%', sm: '294px' }} minWidth={294} className='hi'>
              <CustomSearch
                shadowInput
                placeholderText={searchPlaceholder || 'Search...'}
                onChangeText={(value: string) => onSearch && onSearch(value)}
              />
            </Box>
          )}
        </Stack>
      </Paper>
      <Box
        component='section'
        position='fixed'
        top={contentPosition || '180px'}
        left='65px'
        right='0'
        p={pxToRem(16)}
        sx={{ height: `calc(100vh - ${contentPosition || '180px'} + 8px)`, overflowX: 'hidden', overflowY: 'auto' }}
      >
        <Box p={pxToRem(16)}>{children}</Box>
      </Box>
    </StyledContentWrapper>
  )
}
