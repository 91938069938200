import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import PreLoader from './components/PreLoader'
import AuthLayout from './layouts/AuthLayout'
import AuthRoutes from './routes/authRoutes'
import AdminRoutes from './routes/AdminRoutes'
import ConfigEngineOutlet from './modules/ConfigurationEngine/ConfigEngineOutlet'
import NoMatch from './modules/NoMatch'
import ConfigurationEngine from './modules/ConfigurationEngine/ConfigEngineIndexPage/views/ConfigurationEngine'
import HomeOutlet from './modules/Home/HomeOutlet'
import { DashboardRoutes } from './routes/dashboardRoutes'
import Home from './modules/Home/Home'

function App() {
  return (
    <div className='App'>
      <Toaster />
      <Routes>
        <Route element={<HomeOutlet />}>
          <Route index element={<Home />} />
          {DashboardRoutes.map(({ component: Component, path }) => (
            <Route
              path={path}
              key={path}
              element={
                <Suspense fallback={<PreLoader />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>

        <Route element={<ConfigEngineOutlet />}>
          <Route index element={<ConfigurationEngine />} />
          {AdminRoutes.map(({ component: Component, path }) => (
            <Route
              path={path}
              key={path}
              element={
                <Suspense fallback={<PreLoader />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>

        <Route element={<AuthLayout />}>
          {AuthRoutes.map(({ component: Component, path }) => (
            <Route
              path={path}
              key={path}
              element={
                <Suspense fallback={<PreLoader />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>

        <Route index element={<NoMatch />} />
      </Routes>
    </div>
  )
}

export default App
