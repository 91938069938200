import { lazy } from 'react'

const AuthRoutes = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../modules/Authentication/Login/Login')),
    children: [],
  },
  {
    path: `/reset-password`,
    exact: true,
    component: lazy(() => import('../modules/Authentication/ResetPassword/ResetPassword')),
  },
  {
    path: `/forgot-password`,
    exact: true,
    component: lazy(() => import('../modules/Authentication/ForgotPassword/ForgotPassword')),
  },
]

export default AuthRoutes
