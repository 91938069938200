export interface ISidebarIcon {
  active: boolean
  color?: string
}

export const BackIcon = ({ color }: { color?: string }) => {
  return (
    <svg width='23' height='20' viewBox='0 0 28 25' fill={color || 'currentColor'} xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4332 1.34184C11.4334 1.11434 11.3508 0.892467 11.197 0.707273C11.0431 0.522079 10.8257 0.382831 10.5751 0.309016C10.3245 0.235201 10.0533 0.230513 9.79949 0.295608C9.54565 0.360703 9.32186 0.492323 9.15944 0.672049L0.267636 10.4898C0.0941762 10.6813 0 10.917 0 11.1596C0 11.4022 0.0941762 11.6379 0.267636 11.8294L9.15944 21.6472C9.32186 21.8269 9.54565 21.9585 9.79949 22.0236C10.0533 22.0887 10.3245 22.0841 10.5751 22.0102C10.8257 21.9364 11.0431 21.7972 11.197 21.612C11.3508 21.4268 11.4334 21.2049 11.4332 20.9774V16.6249C18.2659 16.747 21.5483 17.8608 23.1971 19.0717C24.7646 20.2225 25.0516 21.5763 25.3502 22.9944L25.4276 23.3599C25.4846 23.6214 25.6509 23.8559 25.8953 24.0195C26.1396 24.183 26.4451 24.2643 26.7543 24.2479C27.0635 24.2316 27.3551 24.1188 27.5742 23.9307C27.7933 23.7426 27.9248 23.4923 27.944 23.2268C28.1612 20.2291 27.8348 15.8853 25.3692 12.2516C22.976 8.72482 18.6661 6.01292 11.4332 5.7293V1.34184Z'
        fill={color || 'currentColor'}
      />
    </svg>
  )
}

export const ClosedEyeIcon = ({ color, height, width }: { color?: string; height?: string; width?: string }) => {
  return (
    <svg width={width || '24'} height={height || '24'} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.342 18.782L7.411 18.264L8.198 15.325C7.01997 14.8905 5.92512 14.2574 4.961 13.453L2.808 15.607L1.393 14.192L3.547 12.039C2.33108 10.5828 1.51409 8.83576 1.176 6.969L3.144 6.61C3.903 10.812 7.579 14 12 14C16.42 14 20.097 10.812 20.856 6.61L22.824 6.968C22.4864 8.83502 21.6697 10.5824 20.454 12.039L22.607 14.192L21.192 15.607L19.039 13.453C18.0749 14.2574 16.98 14.8905 15.802 15.325L16.589 18.265L14.658 18.782L13.87 15.842C12.6324 16.0541 11.3676 16.0541 10.13 15.842L9.342 18.782Z'
        fill={color || '#837777'}
      />
    </svg>
  )
}

export const SuccessIcon = ({ color, height, width }: { color?: string; height?: string; width?: string }) => {
  return (
    <svg width={width || '70'} height={height || '70'} viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 0C17.9091 0 0 17.9091 0 40C0 62.0909 17.9091 80 40 80C62.0909 80 80 62.0909 80 40C80 17.9091 62.0909 0 40 0ZM57.3382 33.2364C57.6574 32.8714 57.9005 32.4463 58.053 31.9861C58.2056 31.5258 58.2645 31.0397 58.2264 30.5563C58.1883 30.0729 58.054 29.6021 57.8312 29.1714C57.6084 28.7407 57.3018 28.3589 56.9293 28.0485C56.5568 27.7381 56.126 27.5053 55.6622 27.3639C55.1984 27.2224 54.711 27.1751 54.2287 27.2248C53.7463 27.2745 53.2788 27.4202 52.8536 27.6532C52.4284 27.8863 52.0541 28.202 51.7527 28.5818L36.1164 47.3418L28.0255 39.2473C27.3396 38.5849 26.4211 38.2184 25.4676 38.2266C24.5142 38.2349 23.6021 38.6174 22.9279 39.2916C22.2537 39.9658 21.8713 40.8778 21.863 41.8313C21.8547 42.7847 22.2212 43.7033 22.8836 44.3891L33.7927 55.2982C34.15 55.6553 34.5778 55.934 35.0488 56.1166C35.5198 56.2992 36.0236 56.3817 36.5283 56.3587C37.0329 56.3358 37.5272 56.208 37.9797 55.9834C38.4322 55.7589 38.8329 55.4425 39.1564 55.0545L57.3382 33.2364Z'
        fill={color || '#2FB755'}
      />
    </svg>
  )
}

export const ErrorIcon = ({ color, height, width }: { color?: string; height?: string; width?: string }) => {
  return (
    <svg width={width || '25'} height={height || '25'} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 0C5.58333 0 0 5.58333 0 12.5C0 19.4167 5.58333 25 12.5 25C19.4167 25 25 19.4167 25 12.5C25 5.58333 19.4167 0 12.5 0ZM13.6896 10.3127V19.3182H11.276V10.3127H13.6896ZM11.1345 7.98229C11.1345 7.62163 11.2538 7.32477 11.4924 7.09173C11.7365 6.85869 12.0667 6.74217 12.4828 6.74217C12.8934 6.74217 13.2208 6.85869 13.4649 7.09173C13.7091 7.32477 13.8311 7.62163 13.8311 7.98229C13.8311 8.3485 13.7063 8.64813 13.4566 8.88117C13.2124 9.11421 12.8879 9.23073 12.4828 9.23073C12.0777 9.23073 11.7504 9.11421 11.5007 8.88117C11.2565 8.64813 11.1345 8.3485 11.1345 7.98229Z'
        fill={color || '#CF2A2A'}
      />
    </svg>
  )
}

export const DashboardIcon = ({ active, color }: ISidebarIcon) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.08268 0.914062C3.24283 0.914063 2.43738 1.24769 1.84351 1.84156C1.24965 2.43542 0.916016 3.24088 0.916016 4.08073V7.2474C0.916016 8.08725 1.24965 8.8927 1.84351 9.48657C2.43738 10.0804 3.24283 10.4141 4.08268 10.4141H7.24935C8.0892 10.4141 8.89465 10.0804 9.48852 9.48657C10.0824 8.8927 10.416 8.08725 10.416 7.2474V4.08073C10.416 3.24088 10.0824 2.43542 9.48852 1.84156C8.89465 1.24769 8.0892 0.914063 7.24935 0.914062H4.08268ZM4.08268 13.5807C3.24283 13.5807 2.43738 13.9144 1.84351 14.5082C1.24965 15.1021 0.916016 15.9075 0.916016 16.7474V19.9141C0.916016 20.7539 1.24965 21.5594 1.84351 22.1532C2.43738 22.7471 3.24283 23.0807 4.08268 23.0807H7.24935C8.0892 23.0807 8.89465 22.7471 9.48852 22.1532C10.0824 21.5594 10.416 20.7539 10.416 19.9141V16.7474C10.416 15.9075 10.0824 15.1021 9.48852 14.5082C8.89465 13.9144 8.0892 13.5807 7.24935 13.5807H4.08268ZM13.5827 4.08073C13.5827 3.24088 13.9163 2.43542 14.5102 1.84156C15.104 1.24769 15.9095 0.914063 16.7493 0.914062H19.916C20.7559 0.914063 21.5613 1.24769 22.1552 1.84156C22.7491 2.43542 23.0827 3.24088 23.0827 4.08073V7.2474C23.0827 8.08725 22.7491 8.8927 22.1552 9.48657C21.5613 10.0804 20.7559 10.4141 19.916 10.4141H16.7493C15.9095 10.4141 15.104 10.0804 14.5102 9.48657C13.9163 8.8927 13.5827 8.08725 13.5827 7.2474V4.08073ZM13.5827 16.7474C13.5827 15.9075 13.9163 15.1021 14.5102 14.5082C15.104 13.9144 15.9095 13.5807 16.7493 13.5807H19.916C20.7559 13.5807 21.5613 13.9144 22.1552 14.5082C22.7491 15.1021 23.0827 15.9075 23.0827 16.7474V19.9141C23.0827 20.7539 22.7491 21.5594 22.1552 22.1532C21.5613 22.7471 20.7559 23.0807 19.916 23.0807H16.7493C15.9095 23.0807 15.104 22.7471 14.5102 22.1532C13.9163 21.5594 13.5827 20.7539 13.5827 19.9141V16.7474Z'
        fill={color ? color : active ? '#CF2A2A' : '#fff'}
      />
    </svg>
  )
}

export const ConfigEngineIcon = ({ active, color }: ISidebarIcon) => {
  return (
    <svg width='24' height='25' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.5046 4.48752L15.1667 10.1384H1.91194L3.61553 4.48752H13.5046Z' fill={color ? color : active ? '#CF2A2A' : '#fff'} />
      <path d='M4.94454 11.1774L10.6371 12.5486L4.03045 24.0166L0 19.7368L4.94454 11.1774Z' fill={color ? color : active ? '#CF2A2A' : '#fff'} />
      <path d='M6.44149 21.9394L10.4719 17.6596L17.1201 29.1277L11.386 30.4989L6.44149 21.9394Z' fill={color ? color : active ? '#CF2A2A' : '#fff'} />
      <path d='M26.3855 25.9694H16.4964L14.8344 20.3601H28.0891' fill={color ? color : active ? '#CF2A2A' : '#fff'} />
      <path d='M29.9997 10.7205L25.0551 19.3215L19.3627 17.9503L25.9693 6.48225' fill={color ? color : active ? '#CF2A2A' : '#fff'} />
      <path d='M23.559 8.5595L19.5286 12.8392L12.8804 1.32962L18.6145 0L23.559 8.5595Z' fill={color ? color : active ? '#CF2A2A' : '#fff'} />
    </svg>
  )
}

export const WorkflowIcon = ({ active, color }: ISidebarIcon) => {
  return (
    <svg width='25' height='26' viewBox='0 0 46 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.91626 45.3325C7.43678 45.3321 5.98655 44.9203 4.72762 44.1431C3.4687 43.366 2.45067 42.2541 1.78731 40.9316C1.12395 39.6092 0.841391 38.1284 0.971201 36.6546C1.10101 35.1808 1.63808 33.7722 2.52239 32.5861C3.4067 31.4 4.60342 30.4831 5.97881 29.938C7.3542 29.3929 8.85408 29.2409 10.3108 29.4992C11.7676 29.7574 13.1239 30.4157 14.2281 31.4004C15.3323 32.385 16.1409 33.6574 16.5636 35.0752H29.4308V30.5164H33.9896V17.3917L28.8769 12.2813H15.7544V16.84H2.07808V3.16369H15.7544V7.72247H28.8769L36.269 0.328125L45.9404 10.0019L38.5484 17.3894V30.5164H43.1071V44.1928H29.4308V39.634H16.5636C16.0723 41.2805 15.0627 42.7246 13.6848 43.7513C12.307 44.778 10.6346 45.3325 8.91626 45.3325ZM8.91626 33.9355C8.46711 33.9356 8.02239 34.0243 7.60748 34.1963C7.19258 34.3683 6.81562 34.6204 6.49813 34.9381C6.18063 35.2558 5.92883 35.6329 5.75708 36.0479C5.58534 36.4629 5.49702 36.9077 5.49717 37.3569C5.49732 37.806 5.58594 38.2507 5.75796 38.6656C5.92998 39.0805 6.18203 39.4575 6.49974 39.775C6.81744 40.0925 7.19457 40.3443 7.60959 40.516C8.02461 40.6878 8.46939 40.7761 8.91854 40.7759C9.82564 40.7756 10.6955 40.415 11.3367 39.7734C11.9779 39.1317 12.3379 38.2617 12.3376 37.3546C12.3373 36.4475 11.9767 35.5776 11.3351 34.9364C10.6934 34.2952 9.82336 33.9352 8.91626 33.9355ZM38.5484 35.0752H33.9896V39.634H38.5484V35.0752ZM36.269 6.77881L33.0459 10.0019L36.269 13.2249L39.492 10.0019L36.269 6.77881ZM11.1957 7.72247H6.63687V12.2813H11.1957V7.72247Z'
        fill={color ? color : active ? '#CF2A2A' : '#fff'}
      />
    </svg>
  )
}

export const LogoIcon = () => (
  <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='26' cy='26' r='26' fill='#991111' fillOpacity='0.83' />
    <g filter='url(#filter0_i_1463_41360)'>
      <circle cx='33.6241' cy='14.1527' r='7.73472' fill='white' />
    </g>
    <defs>
      <filter
        id='filter0_i_1463_41360'
        x='25.8894'
        y='6.41797'
        width='15.4695'
        height='19.4688'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_1463_41360' />
      </filter>
    </defs>
  </svg>
)

export const ProductFactoryIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='24' height='24' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.1429 28.1259C17.1431 28.4454 17.2365 28.7595 17.4145 29.0386C17.5924 29.3176 17.8488 29.5522 18.1594 29.7201C18.47 29.888 18.8245 29.9837 19.1893 29.9981C19.5541 30.0125 19.917 29.9451 20.2436 29.8022L28.815 26.052C29.1709 25.8964 29.4702 25.6571 29.6795 25.3611C29.8888 25.065 29.9998 24.7238 30 24.3756V13.5674C29.9998 13.2479 29.9063 12.9337 29.7284 12.6547C29.5505 12.3757 29.2941 12.1411 28.9834 11.9732C28.6728 11.8052 28.3183 11.7096 27.9536 11.6952C27.5888 11.6808 27.2259 11.7482 26.8993 11.891L18.3279 15.6413C17.972 15.7969 17.6726 16.0362 17.4633 16.3322C17.254 16.6283 17.1431 16.9695 17.1429 17.3177V28.1259ZM26.1664 8.01703C26.5219 7.86114 26.8208 7.62178 27.0296 7.32572C27.2385 7.02967 27.3491 6.6886 27.3491 6.34067C27.3491 5.99274 27.2385 5.65167 27.0296 5.35562C26.8208 5.05957 26.5219 4.82021 26.1664 4.66431L15.9579 0.197762C15.6604 0.0677059 15.3325 0 15 0C14.6675 0 14.3396 0.0677059 14.0421 0.197762L3.83357 4.66431C3.47811 4.82021 3.17923 5.05957 2.97035 5.35562C2.76148 5.65167 2.65086 5.99274 2.65086 6.34067C2.65086 6.6886 2.76148 7.02967 2.97035 7.32572C3.17923 7.62178 3.47811 7.86114 3.83357 8.01703L14.0421 12.4836C14.3396 12.6136 14.6675 12.6813 15 12.6813C15.3325 12.6813 15.6604 12.6136 15.9579 12.4836L26.1664 8.01703ZM3.10071 11.891C2.7741 11.7482 2.4112 11.6808 2.04644 11.6952C1.68169 11.7096 1.32718 11.8052 1.01656 11.9732C0.705938 12.1411 0.449505 12.3757 0.271594 12.6547C0.0936833 12.9337 0.000194934 13.2479 0 13.5674V24.3756C0.000212461 24.7238 0.111178 25.065 0.320469 25.3611C0.52976 25.6571 0.829112 25.8964 1.185 26.052L9.75643 29.8022C10.083 29.9451 10.4459 30.0125 10.8107 29.9981C11.1755 29.9837 11.53 29.888 11.8406 29.7201C12.1512 29.5522 12.4076 29.3176 12.5855 29.0386C12.7635 28.7595 12.8569 28.4454 12.8571 28.1259V17.3177C12.8569 16.9695 12.746 16.6283 12.5367 16.3322C12.3274 16.0362 12.028 15.7969 11.6721 15.6413L3.10071 11.891Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const AccessManagementIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='24' height='24' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M32 16C32 24.8375 24.8375 32 16 32C7.1625 32 0 24.8375 0 16C0 7.1625 7.1625 0 16 0C24.8375 0 32 7.1625 32 16ZM10.0938 10.6187C9.33125 10.2937 8.45 10.6438 8.125 11.4062C7.8 12.1687 8.15 13.05 8.9125 13.375L9.65625 13.6938C10.7375 14.1562 11.8563 14.5 13.0063 14.7125V17.8438C13.0063 18.1125 12.9625 18.3813 12.875 18.6313L11.0813 24.0125C10.8188 24.8 11.2437 25.65 12.0312 25.9125C12.8188 26.175 13.6687 25.75 13.9312 24.9625L15.4563 20.3875C15.5375 20.15 15.7563 19.9875 16.0063 19.9875C16.2563 19.9875 16.4812 20.15 16.5562 20.3875L18.0812 24.9625C18.3438 25.75 19.1938 26.175 19.9813 25.9125C20.7688 25.65 21.1938 24.8 20.9312 24.0125L19.1375 18.6313C19.05 18.375 19.0063 18.1125 19.0063 17.8438V14.7188C20.1562 14.5 21.275 14.1625 22.3563 13.7L23.1 13.3813C23.8625 13.0563 24.2125 12.175 23.8875 11.4125C23.5625 10.65 22.6813 10.3 21.9188 10.625L21.1688 10.9375C19.5375 11.6375 17.7812 12 16 12C14.2188 12 12.4688 11.6375 10.8313 10.9375L10.0875 10.6187H10.0938ZM16 10C17.3813 10 18.5 8.88125 18.5 7.5C18.5 6.11875 17.3813 5 16 5C14.6187 5 13.5 6.11875 13.5 7.5C13.5 8.88125 14.6187 10 16 10Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const CustomerManagementIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='24' height='18' viewBox='0 0 35 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5027 6.7318C17.5027 8.51719 16.7934 10.2295 15.531 11.4919C14.2685 12.7544 12.5563 13.4636 10.7709 13.4636C8.9855 13.4636 7.27324 12.7544 6.01078 11.4919C4.74832 10.2295 4.03908 8.51719 4.03908 6.7318C4.03908 4.94642 4.74832 3.23416 6.01078 1.9717C7.27324 0.709242 8.9855 0 10.7709 0C12.5563 0 14.2685 0.709242 15.531 1.9717C16.7934 3.23416 17.5027 4.94642 17.5027 6.7318ZM26.9272 13.4636C28.3555 13.4636 29.7253 12.8962 30.7353 11.8862C31.7453 10.8763 32.3127 9.50647 32.3127 8.07817C32.3127 6.64986 31.7453 5.28005 30.7353 4.27008C29.7253 3.26011 28.3555 2.69272 26.9272 2.69272C25.4989 2.69272 24.1291 3.26011 23.1191 4.27008C22.1092 5.28005 21.5418 6.64986 21.5418 8.07817C21.5418 9.50647 22.1092 10.8763 23.1191 11.8862C24.1291 12.8962 25.4989 13.4636 26.9272 13.4636ZM24.2345 20.4485V20.1954C24.2364 18.7385 23.7638 17.3207 22.8881 16.1563H31.7014C33.5217 16.1563 35 17.6319 35 19.4549C35 19.4549 35 25.5809 26.9218 25.5809C25.3116 25.5809 24.0218 25.3385 22.9905 24.9481C23.6337 23.7694 24.0424 22.4774 24.1941 21.1433C24.2103 20.9763 24.221 20.8336 24.2264 20.7151L24.2345 20.4458V20.4485ZM21.5418 20.1954C21.5418 19.1242 21.1162 18.0968 20.3588 17.3393C19.6013 16.5819 18.5739 16.1563 17.5027 16.1563H4.03908C2.96785 16.1563 1.9405 16.5819 1.18302 17.3393C0.425545 18.0968 0 19.1242 0 20.1954V20.3974C0 20.3974 0 28.2736 10.7709 28.2736C21.0355 28.2736 21.5175 21.1217 21.5418 20.4485V20.1954Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const PaymentModuleIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='24' height='17' viewBox='0 0 30 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.09835 1.09835C1.80161 0.395088 2.75544 0 3.75 0H26.25C27.2446 0 28.1984 0.395088 28.9016 1.09835C29.6049 1.80161 30 2.75544 30 3.75V5.625H0V3.75C0 2.75544 0.395088 1.80161 1.09835 1.09835Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 9.375H30V18.75C30 19.7446 29.6049 20.6984 28.9016 21.4016C28.1984 22.1049 27.2446 22.5 26.25 22.5H3.75C2.75544 22.5 1.80161 22.1049 1.09835 21.4016C0.395088 20.6984 0 19.7446 0 18.75V9.375ZM4.29918 15.5492C3.94754 15.9008 3.75 16.3777 3.75 16.875C3.75 17.3723 3.94754 17.8492 4.29918 18.2008C4.65081 18.5525 5.12772 18.75 5.625 18.75H7.5C7.99728 18.75 8.47419 18.5525 8.82582 18.2008C9.17745 17.8492 9.375 17.3723 9.375 16.875C9.375 16.3777 9.17745 15.9008 8.82582 15.5492C8.47419 15.1975 7.99728 15 7.5 15H5.625C5.12772 15 4.65081 15.1975 4.29918 15.5492ZM11.7992 15.5492C12.1508 15.1975 12.6277 15 13.125 15H15C15.4973 15 15.9742 15.1975 16.3258 15.5492C16.6775 15.9008 16.875 16.3777 16.875 16.875C16.875 17.3723 16.6775 17.8492 16.3258 18.2008C15.9742 18.5525 15.4973 18.75 15 18.75H13.125C12.6277 18.75 12.1508 18.5525 11.7992 18.2008C11.4475 17.8492 11.25 17.3723 11.25 16.875C11.25 16.3777 11.4475 15.9008 11.7992 15.5492Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const LoanManagementIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='24' height='23' viewBox='0 0 30 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M28.7052 23.897C28.3107 23.5157 27.8155 23.3399 27.3111 23.4027C26.464 23.5077 25.3548 23.7556 24.1804 24.0181C22.5947 24.3726 20.955 24.7391 19.6996 24.8241C18.6151 24.8974 16.6402 24.5912 16.0552 24.259C15.6323 24.019 15.8922 23.5173 16.3784 23.5173H17.7499C18.754 23.5173 19.5708 22.6321 19.5708 21.5439V19.9943C19.5708 18.9061 18.754 18.021 17.7499 18.021H11.0783C10.7949 18.0115 7.39096 17.942 5.03378 20.1211C4.61659 20.5067 4.28477 20.8711 4.02017 21.2023C3.74025 20.8232 3.31011 20.5796 2.82826 20.5796H0.653423C0.292547 20.5796 0 20.8967 0 21.2878V31.2919C0 31.4797 0.0688894 31.6599 0.191359 31.7927C0.313954 31.9255 0.480172 32.0001 0.653423 32.0001L2.8282 32C3.66987 32 4.35459 31.2579 4.35459 30.3458C4.35459 30.0514 4.60358 29.819 4.89621 29.8514C5.71453 29.942 7.14931 30.1566 9.59822 30.6549C13.2819 31.4045 15.5805 31.6586 17.3914 31.6585C18.6893 31.6585 19.737 31.5281 20.864 31.356C22.2571 31.1435 24.0094 30.6064 25.5553 30.1324C26.2709 29.913 26.9469 29.7059 27.5451 29.5424C29.1569 29.1018 29.3976 28.0003 29.3976 27.3829V25.5789C29.3976 24.9449 29.1387 24.3162 28.7052 23.897Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
    <path
      d='M6.87868 0.732233C7.44129 0.263392 8.20435 0 9 0H27C27.7956 0 28.5587 0.263392 29.1213 0.732233C29.6839 1.20107 30 1.83696 30 2.5V3.75H6V2.5C6 1.83696 6.31607 1.20107 6.87868 0.732233Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 6.25H30V12.5C30 13.163 29.6839 13.7989 29.1213 14.2678C28.5587 14.7366 27.7956 15 27 15H9C8.20435 15 7.44129 14.7366 6.87868 14.2678C6.31607 13.7989 6 13.163 6 12.5V6.25Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const ReportingIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='25' height='27' viewBox='0 0 34 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.21 0.289062C4.75596 0.289063 3.36147 0.826244 2.33331 1.78243C1.30515 2.73862 0.727539 4.03549 0.727539 5.38775V35.9799C0.727539 37.3321 1.30515 38.629 2.33331 39.5852C3.36147 40.5414 4.75596 41.0785 6.21 41.0785H28.1398C29.5939 41.0785 30.9883 40.5414 32.0165 39.5852C33.0447 38.629 33.6223 37.3321 33.6223 35.9799V14.0912C33.622 12.7391 33.0441 11.4424 32.0159 10.4864L22.6574 1.78298C21.6294 0.826722 20.2352 0.289351 18.7813 0.289062H6.21ZM11.6925 25.7825C11.6925 25.1064 11.4036 24.4579 10.8896 23.9798C10.3755 23.5017 9.67824 23.2331 8.95122 23.2331C8.2242 23.2331 7.52696 23.5017 7.01288 23.9798C6.4988 24.4579 6.21 25.1064 6.21 25.7825V33.4305C6.21 34.1066 6.4988 34.7551 7.01288 35.2332C7.52696 35.7113 8.2242 35.9799 8.95122 35.9799C9.67824 35.9799 10.3755 35.7113 10.8896 35.2332C11.4036 34.7551 11.6925 34.1066 11.6925 33.4305V25.7825ZM17.1749 18.1345C17.9019 18.1345 18.5992 18.403 19.1132 18.8811C19.6273 19.3592 19.9161 20.0077 19.9161 20.6838V33.4305C19.9161 34.1066 19.6273 34.7551 19.1132 35.2332C18.5992 35.7113 17.9019 35.9799 17.1749 35.9799C16.4479 35.9799 15.7506 35.7113 15.2366 35.2332C14.7225 34.7551 14.4337 34.1066 14.4337 33.4305V20.6838C14.4337 20.0077 14.7225 19.3592 15.2366 18.8811C15.7506 18.403 16.4479 18.1345 17.1749 18.1345ZM28.1398 15.5851C28.1398 14.909 27.851 14.2606 27.3369 13.7825C26.8229 13.3044 26.1256 13.0358 25.3986 13.0358C24.6716 13.0358 23.9743 13.3044 23.4603 13.7825C22.9462 14.2606 22.6574 14.909 22.6574 15.5851V33.4305C22.6574 34.1066 22.9462 34.7551 23.4603 35.2332C23.9743 35.7113 24.6716 35.9799 25.3986 35.9799C26.1256 35.9799 26.8229 35.7113 27.3369 35.2332C27.851 34.7551 28.1398 34.1066 28.1398 33.4305V15.5851Z'
      fill={color ? color : active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const EodProcessingIcon = ({ active, color }: ISidebarIcon) => (
  <svg width='24' height='23' viewBox='0 0 30 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M28.7052 23.897C28.3107 23.5157 27.8155 23.3399 27.3111 23.4027C26.464 23.5077 25.3548 23.7556 24.1804 24.0181C22.5947 24.3726 20.955 24.7391 19.6996 24.8241C18.6151 24.8974 16.6402 24.5912 16.0552 24.259C15.6323 24.019 15.8922 23.5173 16.3784 23.5173H17.7499C18.754 23.5173 19.5708 22.6321 19.5708 21.5439V19.9943C19.5708 18.9061 18.754 18.021 17.7499 18.021H11.0783C10.7949 18.0115 7.39096 17.942 5.03378 20.1211C4.61659 20.5067 4.28477 20.8711 4.02017 21.2023C3.74025 20.8232 3.31011 20.5796 2.82826 20.5796H0.653423C0.292547 20.5796 0 20.8967 0 21.2878V31.2919C0 31.4797 0.0688894 31.6599 0.191359 31.7927C0.313954 31.9255 0.480172 32.0001 0.653423 32.0001L2.8282 32C3.66987 32 4.35459 31.2579 4.35459 30.3458C4.35459 30.0514 4.60358 29.819 4.89621 29.8514C5.71453 29.942 7.14931 30.1566 9.59822 30.6549C13.2819 31.4045 15.5805 31.6586 17.3914 31.6585C18.6893 31.6585 19.737 31.5281 20.864 31.356C22.2571 31.1435 24.0094 30.6064 25.5553 30.1324C26.2709 29.913 26.9469 29.7059 27.5451 29.5424C29.1569 29.1018 29.3976 28.0003 29.3976 27.3829V25.5789C29.3976 24.9449 29.1387 24.3162 28.7052 23.897Z'
      fill={active ? '#CF2A2A' : '#fff'}
    />
    <path
      d='M6.87868 0.732233C7.44129 0.263392 8.20435 0 9 0H27C27.7956 0 28.5587 0.263392 29.1213 0.732233C29.6839 1.20107 30 1.83696 30 2.5V3.75H6V2.5C6 1.83696 6.31607 1.20107 6.87868 0.732233Z'
      fill={active ? '#CF2A2A' : '#fff'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 6.25H30V12.5C30 13.163 29.6839 13.7989 29.1213 14.2678C28.5587 14.7366 27.7956 15 27 15H9C8.20435 15 7.44129 14.7366 6.87868 14.2678C6.31607 13.7989 6 13.163 6 12.5V6.25Z'
      fill={active ? '#CF2A2A' : '#fff'}
    />
  </svg>
)

export const LogoutIcon = () => (
  <svg width='24' height='24' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5 0C7.84972 0 0 7.84972 0 17.5V17.5019L9.72222 9.72417V15.5575H23.3333V19.4464H9.72222V25.2797L0 17.5019C0.00194444 27.1522 7.84972 35 17.5 35C27.1503 35 35 27.1503 35 17.5C35 7.84972 27.1503 0 17.5 0Z'
      fill='#fff'
    />
  </svg>
)

export const SmallEyeIcon = () => (
  <svg width='14' height='7' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.33333 2.08333C8.02666 2.08765 7.72225 2.1315 7.4294 2.21354C7.56486 2.42795 7.63699 2.67003 7.63889 2.91667C7.63889 3.10818 7.59697 3.29781 7.51554 3.47475C7.43411 3.65168 7.31476 3.81245 7.16429 3.94786C7.01382 4.08328 6.8352 4.1907 6.6386 4.26399C6.44201 4.33728 6.2313 4.375 6.01851 4.375C5.74447 4.37329 5.47549 4.30838 5.23726 4.18646C5.04931 4.77312 5.07121 5.39814 5.29988 5.97298C5.52854 6.54782 5.95237 7.04333 6.51133 7.38934C7.07029 7.73534 7.73604 7.91429 8.41428 7.90084C9.09253 7.88739 9.74888 7.68223 10.2904 7.3144C10.8318 6.94658 11.231 6.43475 11.4313 5.8514C11.6316 5.26806 11.6229 4.64278 11.4065 4.06413C11.19 3.48549 10.7767 2.98281 10.2251 2.62731C9.67359 2.2718 9.01174 2.08149 8.33333 2.08333ZM16.566 4.61979C14.9968 1.86432 11.8903 0 8.33333 0C4.77632 0 1.66897 1.86563 0.100679 4.62005C0.0344871 4.73789 0 4.86809 0 5.00013C0 5.13217 0.0344871 5.26237 0.100679 5.38021C1.66984 8.13568 4.77632 10 8.33333 10C11.8903 10 14.9977 8.13438 16.566 5.37995C16.6322 5.26211 16.6667 5.13191 16.6667 4.99987C16.6667 4.86783 16.6322 4.73763 16.566 4.61979ZM8.33333 8.75C5.47887 8.75 2.86197 7.31771 1.44877 5C2.86197 2.68229 5.47858 1.25 8.33333 1.25C11.1881 1.25 13.8047 2.68229 15.2179 5C13.805 7.31771 11.1881 8.75 8.33333 8.75Z'
      fill='#636363'
    />
  </svg>
)
export const UserAccountIcon = () => (
  <svg width='134' height='134' viewBox='0 0 134 134' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M133.666 67.0026C133.666 84.6837 126.643 101.641 114.14 114.143C101.638 126.645 84.6808 133.669 66.9997 133.669C49.3186 133.669 32.3616 126.645 19.8592 114.143C7.3568 101.641 0.333008 84.6837 0.333008 67.0026C0.333008 49.3215 7.3568 32.3646 19.8592 19.8622C32.3616 7.35973 49.3186 0.335938 66.9997 0.335938C84.6808 0.335938 101.638 7.35973 114.14 19.8622C126.643 32.3646 133.666 49.3215 133.666 67.0026ZM83.6663 42.0026C83.6663 46.4229 81.9104 50.6621 78.7848 53.7877C75.6592 56.9133 71.4199 58.6693 66.9997 58.6693C62.5794 58.6693 58.3402 56.9133 55.2146 53.7877C52.089 50.6621 50.333 46.4229 50.333 42.0026C50.333 37.5823 52.089 33.3431 55.2146 30.2175C58.3402 27.0919 62.5794 25.3359 66.9997 25.3359C71.4199 25.3359 75.6592 27.0919 78.7848 30.2175C81.9104 33.3431 83.6663 37.5823 83.6663 42.0026ZM66.9997 75.3359C59.0214 75.3343 51.2103 77.6233 44.4948 81.931C37.7793 86.2386 32.4417 92.3837 29.1163 99.6359C33.8053 105.091 39.6186 109.467 46.1575 112.465C52.6965 115.463 59.8064 117.01 66.9997 117.003C74.193 117.01 81.3029 115.463 87.8418 112.465C94.3807 109.467 100.194 105.091 104.883 99.6359C101.558 92.3837 96.22 86.2386 89.5045 81.931C82.789 77.6233 74.978 75.3343 66.9997 75.3359Z'
      fill='#444444'
    />
  </svg>
)

export const ActivityStepperIcon = ({ active, action }: { active?: boolean; action?: 'INITIATION' | 'APPROVAL' | 'REJECTION' | 'PENDING ACTIVITY' }) => (
  <svg
    width='23'
    height={action === 'REJECTION' ? '25' : '26'}
    viewBox={action === 'REJECTION' ? '0 0 25 25' : '0 0 25 26'}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='12.5'
      cy={action === 'REJECTION' ? '12.5' : '13'}
      r={!action ? '12' : '12.5'}
      fill={!action ? '#FFF' : action === 'REJECTION' ? '#CF2A2A' : action === 'PENDING ACTIVITY' ? 'grey' : '#2FB755'}
      stroke={!action ? '#AAAAAA' : ''}
    />
    <circle cx='12.3438' cy='13.1562' r='8.34375' fill='white' stroke='#AAAAAA' stroke-width='0.5' />
  </svg>
)

export const DragArrowRight = ({ direction }: { direction: 'right' | 'left' }) => {
  return direction === 'right' ? (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_14442_482740)'>
        <rect x='4' y='4' width='30' height='30' rx='4' fill='white' />
        <path
          d='M25.1816 18.2709L14.0254 8.64983C13.6094 8.29241 13 8.61468 13 9.19183V28.434C13 29.0112 13.6094 29.3334 14.0254 28.976L25.1816 19.3549C25.501 19.0795 25.501 18.5463 25.1816 18.2709Z'
          fill='#636363'
        />
      </g>
      <defs>
        <filter id='filter0_d_14442_482740' x='0' y='0' width='38' height='38' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'>
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_14442_482740' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_14442_482740' result='shape' />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_14442_482742)'>
        <rect x='34' y='34' width='30' height='30' rx='4' transform='rotate(-180 34 34)' fill='white' />
        <path
          d='M12.8184 19.7291L23.9746 29.3502C24.3906 29.7076 25 29.3853 25 28.8082L25 9.56598C25 8.98884 24.3906 8.66657 23.9746 9.02399L12.8184 18.6451C12.499 18.9205 12.499 19.4537 12.8184 19.7291Z'
          fill='#636363'
        />
      </g>
      <defs>
        <filter id='filter0_d_14442_482742' x='0' y='0' width='38' height='38' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'>
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_14442_482742' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_14442_482742' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
