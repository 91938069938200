import { axiosBaseQuery } from '../../config/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

import { ICreateSecurityCofiguration, IPasswordCofiguration, ISessionCofiguration, INewConfigurationResult } from './securityConfigurationApi.types'

export const securityConfigurationApi = createApi({
  reducerPath: 'securityConfigurationApi',
  tagTypes: ['SecurityConfiguration'],
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    updateSecurityCofiguration: builder.mutation<void, ICreateSecurityCofiguration>({
      query: (data) => {
        return {
          url: `/user/api/v1/configurations/user`,
          method: 'patch',
          body: data,
        }
      },
      invalidatesTags: ['SecurityConfiguration'],
    }),
    newUpdateSecurityConfiguration: builder.mutation<void, ICreateSecurityCofiguration>({
      query: (data) => {
        return {
          url: `/api/v1/configs/update/`,
          method: 'patch',
          body: data,
        }
      },
      invalidatesTags: ['SecurityConfiguration'],
    }),
    updatePasswordCofiguration: builder.mutation<void, IPasswordCofiguration>({
      query: (data) => {
        return {
          url: `/user/api/v1/configurations/password`,
          method: 'patch',
          body: data,
        }
      },
      invalidatesTags: ['SecurityConfiguration'],
    }),
    updateSessionCofiguration: builder.mutation<void, ISessionCofiguration>({
      query: (data) => {
        return {
          url: `/user/api/v1/configurations/session`,
          method: 'patch',
          body: data,
        }
      },
      invalidatesTags: ['SecurityConfiguration'],
    }),
    getSecurityConfiguration: builder.query<INewConfigurationResult, void>({
      query() {
        return {
          url: `/api/v1/configs/current`,
          method: 'get',
        }
      },
      providesTags: ['SecurityConfiguration'],
    }),
  }),
})

export const {
  useUpdateSecurityCofigurationMutation,
  useNewUpdateSecurityConfigurationMutation,
  useUpdatePasswordCofigurationMutation,
  useUpdateSessionCofigurationMutation,
  useGetSecurityConfigurationQuery,
} = securityConfigurationApi
