import Breadcrumbs from '@mui/material/Breadcrumbs'
import { styled } from '@mui/material/styles'
export const StyledContentWrapper = styled('div')(() => ({
  position: 'relative',
}))

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.body1,
      color: '#8F8F8F',
    },
  },

  '& .MuiBreadcrumbs-li': {
    '& .MuiLink-root': {
      fontSize: theme.typography.body2,
      fontWeight: 700,
      color: '#8F8F8F',
    },

    '& .MuiTypography-body1.title': {
      fontWeight: 700,
      fontSize: theme.typography.body2,
      color: 'text.secondary',
    },
  },
}))
