import SearchIcon from '@mui/icons-material/Search'
import _debounce from 'lodash.debounce'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material/styles'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'
interface IsearchProps {
  searchText?: string
  placeholderText: string
  onChangeText: (e: string) => void
  shadowInput?: boolean
}

export interface ISearchInput extends StandardTextFieldProps {
  shadowInput?: boolean
}

function CustomSearch(props: IsearchProps) {
  const { onChangeText, placeholderText, shadowInput } = props

  const handleSearch = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeText(event.target.value)
  }, 1000)

  return (
    <TextFieldStyle
      size='small'
      sx={{ '& fieldset': { border: 'none' } }}
      placeholder={placeholderText}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      shadowInput={shadowInput}
      onChange={handleSearch}
      onKeyDown={(e) => {
        if (e.key !== 'Escape') {
          e.stopPropagation()
        }
      }}
      variant={shadowInput ? undefined : 'standard'}
    />
  )
}

export default CustomSearch

const TextFieldStyle = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'shadowInput',
})<ISearchInput>(({ shadowInput }) => {
  if (shadowInput)
    return {
      borderBottom: '1px solid #AAAAAA',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    }
  return {}
})
