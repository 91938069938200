import { Container, Card, CardContent, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

import authscreenBgImg from "../assets/svgs/authscreens_bg_2.svg";
import authscreenBgImg2 from "../assets/svgs/authscreens_bg.svg";

const AuthLayouts = () => {
  return (
    <BackgroundStyle display="flex" alignItems="center" justifyContent="center">
      <StyledBox>
        <Container maxWidth="xs" sx={{ mr: 0, p: 0 }}>
          <CardStyle>
            <CardContent sx={{ p: 5, height: "60vh", overflowY: "auto" }}>
              <Outlet />
            </CardContent>
          </CardStyle>
        </Container>
      </StyledBox>
    </BackgroundStyle>
  );
};

const BackgroundStyle = styled(Box)(({ theme }) => ({
  backgroundSize: "40%, cover",
  backgroundImage: `url(${authscreenBgImg}), url(${authscreenBgImg2})`,
  backgroundRepeat: `no-repeat, no-repeat`,
  backgroundPosition: `bottom left, top left`,
  height: "100vh",
  width: "100vw",
  // overflow: 'scroll',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  // margin: "0 auto",
  // marginTop: "10vh",
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1516,
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 1204,
  },
}));

const CardStyle = styled(Card)(({ theme }) => ({
  boxShadow: "0px 15px 35px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  paddingBottom: theme.spacing(6),
}));

export default AuthLayouts;
