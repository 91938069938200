import { axiosBaseQuery } from '../config/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IPermissionResult, IPermissionsResult } from './types'

interface IPermissionPayload {
  categoryId: string
  subcategoryId: string
  search: string
}
interface IPermissionParam {
  search: string
}

export const permissionApi = createApi({
  reducerPath: 'permissionApi',
  tagTypes: ['Permissions', 'Permission'],
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPermissionCategories: builder.query<IPermissionsResult, IPermissionParam>({
      query: ({ search }) => {
        return {
          url: `/api/v1/modules${search ? '?search=' + search : ''}`,
          method: 'get',
        }
      },
      providesTags: ['Permissions'],
    }),
    getPermissions: builder.query<IPermissionResult, IPermissionPayload>({
      query({ categoryId, subcategoryId, search }) {
        return {
          url: `/api/v1/modules/submodule/${subcategoryId}/permissions${search ? '?search=' + search : ''}`,
          method: 'get',
        }
      },
      providesTags: ['Permission'],
    }),
    getPermissionsByRole: builder.query<IPermissionResult, { roleId: string }>({
      query({ roleId }) {
        return {
          url: `/api/v1/roles/${roleId}/permisssions`,
          method: 'get',
        }
      },
      providesTags: ['Permissions'],
    }),
  }),
})

export const { useGetPermissionCategoriesQuery, useGetPermissionsQuery, useGetPermissionsByRoleQuery } = permissionApi
