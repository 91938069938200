import NEFT from '../../../../assets/svgs/NEFT.svg'
import SODIcon from '../../../../assets/svgs/SOD.svg'
import SecConfig from '../../../../assets/svgs/SecConfig.svg'
import CustomerMgt from '../../../../assets/svgs/CustomerMgt.svg'
import ProductMgt from '../../../../assets/svgs/ProductMgt.svg'
import GlobalOrg from '../../../../assets/svgs/GlobalOrg.svg'
import ChargesSetup from '../../../../assets/svgs/ChargesSetup.svg'
import ChartofAccounts from '../../../../assets/svgs/ChartofAccounts.svg'
import TaxSetup from '../../../../assets/svgs/TaxSetup.svg'
import JournalPosting from '../../../../assets/svgs/JournalPosting.svg'
import GroupIcon from '../../../../assets/svgs/GroupIcon.svg'
import Individual from '../../../../assets/svgs/Individual.svg'
import IndividualSearch from '../../../../assets/svgs/IndividualSearch.svg'
import VirtualAccount from '../../../../assets/svgs/VirtualAccount.svg'
import ThemeSetup from '../../../../assets/svgs/ThemeSetup.svg'
import PaymentModule from '../../../../assets/svgs/PaymentModule.svg'
import WorkflowConfig from '../../../../assets/svgs/WorkflowConfig.svg'
import DateAndTime from '../../../../assets/svgs/DateAndTime.svg'
import LoanSetup from '../../../../assets/svgs/LoanSetup.svg'
// import IntegrationMgt from '../../../../assets/svgs/IntegrationMgt.svg'
// import TeamMgt from '../../../../assets/svgs/TeamMgt.svg'
import CurrencySetup from '../../../../assets/svgs/CurrencySetup.svg'
import FiscalPeriodConfig from '../../../../assets/svgs/FiscalPeriodConfig.svg'

import { IDashboardMenuArray } from '../interfaces/dashboardItems'
import { configEnginePaths } from '@app/routes/path'

export const dashboardMenus: IDashboardMenuArray = [
  {
    slug: 'access-configuration',
    title: 'ACCESS CONFIGURATION',
    children: [
      {
        subtitle: 'Segregation of Duties (SOD)',
        logo: SODIcon,
        path: configEnginePaths.SOD_CONFIGURATION,
      },
      {
        subtitle: 'Security Configuration',
        logo: SecConfig,
        path: configEnginePaths.SECURITY_CONFIGURATION,
      },
    ],
  },
  {
    slug: 'forms-configuration',
    title: 'FORMS CONFIGURATION',
    children: [
      {
        subtitle: 'Customer Management Form Setup ',
        logo: CustomerMgt,
        path: '/',
      },
      {
        subtitle: 'Product Management Form Setup',
        logo: ProductMgt,
        path: '/',
      },
      {
        subtitle: 'Payment Module Form Setup',
        logo: PaymentModule,
        path: '/',
      },
      {
        subtitle: 'Loan Module Form Setup',
        logo: LoanSetup,
        path: '/',
      },
    ],
  },
  {
    slug: 'products-factory-configuration',
    title: 'PRODUCTS FACTORY CONFIGURATION',
    children: [
      {
        subtitle: 'Global Product Organization',
        logo: GlobalOrg,
        path: '/',
      },
      {
        subtitle: 'Default Product Definition',
        logo: GlobalOrg,
        path: '/',
      },
      // {
      //   subtitle: 'Charges Setup',
      //   logo: ChargesSetup,
      //   path: '/',
      // },
      // {
      //   subtitle: 'Charges Setup',
      //   logo: ChargesSetup,
      //   path: '/',
      // },
      // {
      //   subtitle: 'Default Product Definition',
      //   logo: GlobalOrg,
      //   path: '/',
      // },
    ],
  },
  {
    slug: 'account-tax-configuration',
    title: ' Currency, Accounting, and Tax Configuration',
    children: [
      {
        subtitle: 'Chart of Accounts',
        logo: ChartofAccounts,
        path: '/',
      },
      {
        subtitle: 'Charges Management',
        logo: ChargesSetup,
        path: '/',
      },
      {
        subtitle: 'Taxes Management',
        logo: TaxSetup,
        path: '/',
      },
      {
        subtitle: 'Currency Setup',
        logo: CurrencySetup,
        path: '/',
      },
      {
        subtitle: 'Journal Posting',
        logo: JournalPosting,
        path: '/',
      },
      {
        subtitle: 'Fiscal Period Configuration',
        logo: FiscalPeriodConfig,
        path: '/',
      },
    ],
  },
  {
    slug: 'customer-management',
    title: 'CUSTOMER MANAGEMENT ',
    children: [
      {
        subtitle: 'Concession Group Setup',
        logo: GroupIcon,
        path: '/',
      },
      {
        subtitle: 'Group Contracts',
        logo: GroupIcon,
        path: '/',
      },
      {
        subtitle: 'Individual Customer Contract',
        logo: Individual,
        path: '/',
      },
      {
        subtitle: 'Risk Rating Setup',
        logo: IndividualSearch,
        path: '/',
      },
    ],
  },
  {
    slug: 'virtual-account',
    title: 'VIRTUAL ACCOUNT',
    children: [
      {
        subtitle: 'Individual Virtual Account',
        logo: VirtualAccount,
        path: '/',
      },
      {
        subtitle: 'Bulk Virtual Account',
        logo: VirtualAccount,
        path: '/',
      },
    ],
  },
  {
    slug: 'workflow-jobs',
    title: 'WORKFLOW/JOBS',
    children: [
      {
        subtitle: 'Workflow Configuration',
        logo: WorkflowConfig,
        path: '/',
      },
      {
        subtitle: 'Automated Processing',
        logo: NEFT,
        path: '/',
      },
    ],
  },
  {
    slug: 'personalization',
    title: 'PERSONALIZATION',
    children: [
      {
        subtitle: 'Theme Setup',
        logo: ThemeSetup,
        path: '/',
      },
      {
        subtitle: 'Date & Time Setup',
        logo: DateAndTime,
        path: '/',
      },
    ],
  },
  {
    slug: 'integrations',
    title: 'INTEGRATIONS',
    children: [
      {
        subtitle: 'Integration Management',
        logo: SODIcon,
        path: '/',
      },
    ],
  },
  {
    slug: 'teams-setup',
    title: 'TEAMS SETUP',
    children: [
      {
        subtitle: 'Team Management',
        logo: GroupIcon,
        path: '/',
      },
    ],
  },
]
