import { configEnginePaths, userAndAccessMgtPaths, productFactoryPaths, paymentPaths, customerMgtPaths, loanPaths } from '@app/routes/path'
import {
  AccessManagementIcon,
  ConfigEngineIcon,
  CustomerManagementIcon,
  LoanManagementIcon,
  PaymentModuleIcon,
  ProductFactoryIcon,
  ReportingIcon,
  WorkflowIcon,
} from '../components/icons'

export const MODULES_LIST = [
  {
    slug: 'config-engine',
    icon: ConfigEngineIcon,
    title: 'Configuration Engine',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: true,
    path: configEnginePaths.CONFIG_ENGINE,
  },
  {
    slug: 'access-control',
    icon: AccessManagementIcon,
    title: 'User & Access Control',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: true,
    path: userAndAccessMgtPaths.USER_AND_ACCESS_MANAGEMENT,
  },
  {
    slug: 'product-factory',
    icon: ProductFactoryIcon,
    title: 'Product Factory',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: productFactoryPaths.PRODUCT_FACTORY,
  },
  {
    slug: 'customer-mgt',
    icon: CustomerManagementIcon,
    title: 'Customer Management',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: customerMgtPaths.CUSTOMER_MANAGEMENT,
  },
  {
    slug: 'payment-module',
    icon: PaymentModuleIcon,
    title: 'Payments',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: paymentPaths.PAYMENT,
  },
  {
    slug: 'loan-management',
    icon: LoanManagementIcon,
    title: 'Loan Module',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: loanPaths.LOAN,
  },
  {
    slug: 'reporting-module',
    icon: ReportingIcon,
    title: 'Reporting',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: '',
  },
  {
    slug: 'workflow-module',
    icon: WorkflowIcon,
    title: 'Workflow',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: '',
  },
  {
    slug: 'eod-processing',
    icon: ConfigEngineIcon,
    title: 'EOD Processing',
    description: 'The configuration engine module is where you can define the functionalities of the entire system',
    space: 4,
    available: false,
    path: '',
  },
]
