import { Outlet } from 'react-router-dom'
import AuthGaurd from '@app/layouts/gaurds/AuthGaurd'
import DashboardLayout from '@app/layouts/Dashboard/DashboardLayout'

const HomeOutlet = () => {
  return (
    <AuthGaurd>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </AuthGaurd>
  )
}

export default HomeOutlet
