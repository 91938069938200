import Auth from './auth'

export function formatApiErrorMessage(apiError: any) {
  let errorMessage

  switch (typeof apiError) {
    case 'object':
      typeof apiError?.message === 'string' ? (errorMessage = apiError?.message) : (errorMessage = 'Error occured, please try again')
      break

    case 'string':
      errorMessage = apiError
      break

    default:
      errorMessage = 'Error occured, please try again'
      break
  }

  if (typeof apiError.error === 'object') {
    if (apiError.error?.msg) {
      errorMessage = apiError.error?.msg
    }
  }

  return errorMessage
}

export const handleApiError = (err: any) => {
  const error: {
    detail?: { message?: any }
    errors?: { message?: any }
    message?: any
  } = err?.response?.data

  let formatedError = 'Something happened when trying to connect to the server'

  if (+err?.response?.status === +500) {
    return 'Request failed with status code 500'
  }
  if (+err?.response?.status === +502) {
    return 'Request failed with status code 502'
  }
  if (+err?.response?.status === +404) {
    return 'Resource not found'
  }
  if (+err?.response?.status === +401) {
    Auth.removeToken()
    window.history.replaceState('', '', '/login')
    return error.detail || 'Unauthorized Access'
  }

  if (error.detail) {
    if (typeof error.detail === 'string') return error.detail
    for (const [, value] of Object.entries(error.detail || {})) {
      return JSON.stringify(value?.message || value)
    }
  }

  if (error.errors) {
    for (const [, value] of Object.entries(error.errors || {})) {
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          const [v] = Object.values(value || {})
          console.log(v)
        }
        if (typeof value[0] === 'string') {
          return value[0]
        }
      } else if (typeof value === 'string') {
        return value
      } else if (typeof value === 'object') {
        const _v = Object.values(value)
        return _v[0]
      }
    }
  }

  if (!error.detail && typeof error !== 'object') {
    return error
  }

  if (!error.detail && typeof error === 'object') {
    for (const [key, value] of Object.entries(error || {})) {
      if (value === 'permissions') {
        return Object.values(value)
      }
      return `${key} ${value}`
    }
  }

  if (error.message) {
    if (typeof error.message === 'object') {
      for (const [key, value] of Object.entries(error.message || {})) {
        return `${key}: ${value}`
      }
    } else {
      return error.message || error
    }
  }

  return formatedError
}
