import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import HelpIcon from '@mui/icons-material/Help'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import MoreIcon from '@mui/icons-material/MoreVert'
import { ReactComponent as AppbarTeam } from '../../../assets/svgs/AppbarTeam.svg'
import { pxToRem } from '@app/theme/util'
import { IUserProfile } from '@app/api/userApi/userApi.types'
import { Avatar, Button, styled, Tooltip } from '@mui/material'
import { format } from 'date-fns'

const timeFormat = 'hh:mm a, eee dd MMM, yyyy'

export interface IDashboardHeaderProps {
  drawerWidth: number | string
  user?: IUserProfile
}

export default function DashboardAppbar({ drawerWidth, user }: IDashboardHeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null)
  const [date, setDate] = React.useState(new Date())
  const [anchorRoles, setAnchorRoles] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRoles(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setAnchorRoles(null)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setNotificationAnchorEl(null)
  }

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)

    return function cleanup() {
      clearInterval(timerID)
    }
  })

  function tick() {
    setDate(new Date())
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton disableFocusRipple disableRipple disableTouchRipple size='large' aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={4} color='error'>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton disableFocusRipple disableRipple disableTouchRipple size='large' aria-label='show 17 new notifications' color='inherit'>
          <Badge badgeContent={17} color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton disableFocusRipple disableRipple disableTouchRipple color='inherit'>
          <Badge badgeContent={17} color='error'>
            <HelpIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <AppBar position='fixed' color='inherit' sx={{ width: `calc(100% - ${drawerWidth})`, ml: `${drawerWidth}` }} elevation={0}>
        <Toolbar color='text.secondary' sx={{ backgound: (theme) => theme.palette.common.white, minHeight: '50px !important' }}>
          <Typography variant='h6' noWrap component='div' sx={{ display: { xs: 'none', sm: 'block' } }}>
            {format(date, timeFormat)}{' '}
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='center' sx={{ flexGrow: 1, position: 'relative' }}>
            <Tooltip title={user?.data?.roles[0]?.description || ''}>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                id='basic-button'
                aria-controls={Boolean(anchorRoles) ? 'roles-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={Boolean(anchorRoles) ? 'true' : undefined}
                onClick={(e) => user?.data?.roles && user?.data?.roles.length > 1 && handleClick(e)}
                sx={{
                  background: 'rgba(253, 197, 197, 0.17)',
                  border: '1px solid #E5E9EB',
                  borderRadius: '8px',
                  lineHeight: '19px',
                  padding: `${pxToRem(8)} ${pxToRem(25)}`,
                  alignItems: 'center',
                  fontSize: pxToRem(16),
                  fontWeight: 700,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  color: 'text.lightYellow',
                  variant: 'subtitle1',
                  alignSelf: 'center',
                  '&:hover': {
                    background: 'rgba(253, 197, 197, 0.17)',
                    color: 'E5E9EB',
                  },
                }}
              >
                <Box component='span' height={8} width={8} borderRadius='50%' display='inline-flex' sx={{ background: '#D5A62F' }} />
                &nbsp;&nbsp;&nbsp;&nbsp; ASSIGNED ROLE(S):{' '}
                {user?.data?.roles && user?.data?.roles.length > 0 ? user?.data?.roles[0]?.description : 'SUPERADMIN'}
              </Button>
            </Tooltip>
            <Menu
              id='roles-menu'
              anchorEl={anchorRoles}
              open={Boolean(anchorRoles)}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'anchor-roles-button',
              }}
              PaperProps={{
                sx: {
                  width: 200,
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {user?.data?.roles?.slice(1).map((role) => (
                <MenuItem disableRipple disableTouchRipple>
                  <Box component='span' height={8} width={8} borderRadius='50%' display='inline-flex' sx={{ background: '#D5A62F' }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {role?.description}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 0.03 }}>
            <StyledIconButton disableFocusRipple disableRipple disableTouchRipple aria-label='show 4 new mails' color='inherit'>
              <AppbarTeam />
              &nbsp;
              <Typography component='span'>My Team</Typography>
            </StyledIconButton>
            <StyledIconButton
              disableFocusRipple
              disableRipple
              disableTouchRipple
              aria-label='show 0 new notifications'
              aria-controls='appbar-notification-menu'
              aria-haspopup='true'
              color='inherit'
              onClick={handleOpenUserMenu}
            >
              <Badge badgeContent={0} color='error' max={9} showZero>
                <NotificationsIcon sx={{ fontSize: '18px' }} />
              </Badge>
              &nbsp;
              <Typography component='span' ml={1}>
                Notifications
              </Typography>
            </StyledIconButton>
            <Menu
              sx={{ mt: 4 }}
              id='menu-appbar'
              anchorEl={notificationAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(notificationAnchorEl)}
              onClose={handleCloseUserMenu}
            >
              <Box p={2} height={50}>
                <Typography>No notification</Typography>
              </Box>
            </Menu>
            <StyledIconButton disableFocusRipple disableRipple disableTouchRipple aria-label='user help' color='inherit'>
              <HelpIcon />
              &nbsp;
              <Typography component='span'>Help</Typography>
            </StyledIconButton>
            <Tooltip title={`${user?.data?.firstname || ''} ${user?.data?.lastname || ''}`.trim()}>
              <StyledIconButton
                lastItem
                disableFocusRipple
                disableRipple
                disableTouchRipple
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                {user?.data?.image ? (
                  <Avatar
                    alt={`${user?.data?.firstname || ''} ${user?.data?.lastname || ''}`.trim()}
                    src={user?.data?.image}
                    sx={{ height: '2rem', width: '2rem' }}
                  />
                ) : (
                  <AccountCircle />
                )}{' '}
                &nbsp;
                <Typography component='span' textTransform='capitalize'>
                  {`${user?.data?.firstname || ''} ${user?.data?.lastname || ''}`.trim()}
                </Typography>
              </StyledIconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <StyledIconButton aria-label='show more' aria-controls={mobileMenuId} aria-haspopup='true' onClick={handleMobileMenuOpen} color='inherit'>
              <MoreIcon />
            </StyledIconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  )
}

interface IIconButtonProps extends IconButtonProps {
  lastItem?: boolean
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'showQuicklinks' && prop !== 'btnTopPosition',
})<IIconButtonProps>(({ theme, lastItem }) => ({
  border: '1px solid transparent',
  '&:hover': {
    borderColor: '#E5E9EB',
    borderRadius: '8px',
  },
  margin: `0px ${lastItem ? '0' : '16px'} 0px 16px`,
  paddingInline: '4px',
}))
