import { LogoIcon, LogoutIcon } from '@app/components/icons'
import { SIDEBAR_ITEMS } from '@app/constants/sidebarItems'
import { ISidebar } from '@app/layouts/interfaces/sidebar.interface'
import { pxToRem } from '@app/theme/util'
import { Drawer, List, ListItem, Toolbar } from '@mui/material'
import React from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { StyledListItemButton, StyledListItemIcon, StyledListItemText } from './sidebar.styles'

export default function Sidebar({ drawerWidth }: ISidebar) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [hover, setHover] = React.useState<string | null>(null)

  const matchRoute = (path: string) =>
    Boolean(
      matchPath(
        {
          path: `/${path}/*`,
        },
        pathname
      )
    )
  return (
    <Drawer
      color='primary'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          background: (theme) => theme.palette.primary.main,
          width: drawerWidth,
          boxSizing: 'border-box',
          paddingInline: '3px',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Toolbar sx={{ minHeight: '48px !important' }} />
      <List>
        <ListItem disablePadding>
          <StyledListItemButton isActive={false} logo onClick={() => navigate('/dashboard')}>
            <StyledListItemIcon>{LogoIcon()}</StyledListItemIcon>
            <StyledListItemText sx={{ fontWeight: 500, fontSize: pxToRem(14) }} disableTypography primary='Sterling' />
          </StyledListItemButton>
        </ListItem>
      </List>
      <List sx={{ marginTop: pxToRem(24) }}>
        {SIDEBAR_ITEMS.map((item) => (
          <ListItem key={item.slug} disablePadding>
            <StyledListItemButton
              isActive={matchRoute(item.path)}
              onMouseEnter={() => setHover(item.slug)}
              onMouseLeave={() => setHover(null)}
              disableRipple
              onClick={() => navigate(item.path)}
            >
              <StyledListItemIcon>{item.icon(matchRoute(item.path))}</StyledListItemIcon>
              {hover === item.slug && <StyledListItemText disableTypography primary={item.title} />}
            </StyledListItemButton>
          </ListItem>
        ))}
      </List>
      <Toolbar />
      <List>
        <ListItem disablePadding>
          <StyledListItemButton
            isActive={false}
            onMouseEnter={() => setHover('logout')}
            onMouseLeave={() => setHover(null)}
            disableRipple
            onClick={() => {
              localStorage.removeItem('@sterling_core_token')
              return navigate('/login')
            }}
          >
            <StyledListItemIcon>{LogoutIcon()}</StyledListItemIcon>
            {hover === 'logout' && <StyledListItemText disableTypography primary='Log Out' />}
          </StyledListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}
