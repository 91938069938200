import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authApi } from '../api/authApi';
import { roleApi } from '../api/roleApi';
import { permissionApi } from '../api/permissionsApi';
import { userApi } from '../api/userApi/userApi'
import { rolesApi } from '../api/rolesApi/rolesApi'
import { securityConfigurationApi } from '../api/securityConfigurationApi/securityConfigurationApi';
import { sodApi } from '../api/sodApi/sodApi';

export const store = configureStore({
  reducer: {
    // add any feature reducer here
    [authApi.reducerPath]: authApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [securityConfigurationApi.reducerPath]: securityConfigurationApi.reducer,
    [sodApi.reducerPath]: sodApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(permissionApi.middleware)
      .concat(sodApi.middleware)
      .concat(roleApi.middleware)
      .concat(rolesApi.middleware)

})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
