import { MODULES_LIST } from '@app/constants/modulesList'
import ContentLayout from '@app/layouts/Content/ContentLayout'
import { pxToRem } from '@app/theme/util'
import { Avatar, Box, Button, Chip, Divider, Grid, IconButton, List, ListItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledPaper } from './home.style'
import { useGetLoggedInUserQuery } from '@app/api/userApi/userApi'
import HelpIcon from '@mui/icons-material/Help'
import { SmallEyeIcon, UserAccountIcon } from '@app/components/icons'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { GREETING } from '@app/utils/greeting'
import { format } from 'date-fns'
import { IRole } from '@app/api/types'

function Home() {
  const theme = useTheme()
  const { data: user } = useGetLoggedInUserQuery()
  const navigate = useNavigate()
  const [activeCard, setActiveCard] = React.useState('')
  const [selectedRole, setSelectedRole] = React.useState<IRole | null>(null)
  const [data, setData] = useState<any[]>(MODULES_LIST)

  const onViewPermissions = (role: any) => {
    setSelectedRole((prev: any) => ({ ...prev, name: role.name, id: role.id, permissions: role.permissions }))
  }

  const handleGlobalSearch = (searchTerm: string) => {
    setData((prev) =>
      searchTerm
        ? prev.filter(
            (item: any) =>
              item.title?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
              item.description?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
              item.slug?.toLowerCase().includes(searchTerm?.toLowerCase())
          )
        : MODULES_LIST
    )
  }

  return (
    <ContentLayout
      title={`${GREETING()} ${user?.data?.firstname || ''}!`}
      paperStyle={{ pb: 0 }}
      searchPlaceholder='Search all modules'
      onSearch={handleGlobalSearch}
      contentPosition='140px'
      isHome
    >
      <Grid container spacing={2.5} mt={2}>
        <Grid item xs={12} md={4}>
          <StyledPaper available disabled elevation={0} sx={{ height: '100%', cursor: 'default', justifyContent: 'space-between' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box flex={2}>
                <Stack spacing={2}>
                  <Avatar sx={{ width: pxToRem(133.3), height: pxToRem(133.3), marginInline: 'auto', bgcolor: '#fff' }} src={user?.data?.image}>
                    {UserAccountIcon()}
                  </Avatar>
                  <Box>
                    <Typography
                      component='h3'
                      fontWeight={500}
                      fontSize={pxToRem(30)}
                      lineHeight='48px'
                      textAlign='center'
                      letterSpacing='-0.022em'
                      color='#636363'
                    >{`${user?.data?.firstname || '-'} ${user?.data?.lastname || '-'}`}</Typography>
                    <Typography fontWeight={500} fontSize={pxToRem(20)} textAlign='center' letterSpacing='-0.022em' color='#636363'>
                      {user?.data?.username || '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box p={pxToRem(14)} flex={12}>
                <Divider flexItem />
                <Stack spacing={2} height='100%'>
                  <Typography
                    component='h3'
                    fontWeight={500}
                    fontSize={pxToRem(20)}
                    letterSpacing='-0.022em'
                    color='#636363'
                    sx={{ paddingLeft: pxToRem(48), paddingTop: pxToRem(27) }}
                  >
                    Assigned Roles:
                  </Typography>
                  <StyledPaper
                    available
                    disabled
                    elevation={0}
                    sx={{ cursor: 'default', justifyContent: 'flex-start', alignItems: 'flex-start', height: '83%' }}
                  >
                    <Box
                      p={pxToRem(14)}
                      sx={{
                        overflowY: 'auto',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        width: '100%',
                        height: '28vh',
                      }}
                    >
                      {false ? (
                        <Box display='flex' alignItems='center' justifyContent='center' sx={{ height: '100%', width: '100%' }}>
                          Loading...
                        </Box>
                      ) : (
                        <>
                          {!selectedRole &&
                            user?.data?.roles.map((role) => (
                              <Box mb={1}>
                                <Chip
                                  sx={{ fontSize: pxToRem(16), padding: `${pxToRem(4)} ${pxToRem(13)}` }}
                                  deleteIcon={SmallEyeIcon()}
                                  onDelete={() => onViewPermissions(role)}
                                  label={role.name}
                                />
                              </Box>
                            ))}

                          {selectedRole && (
                            <>
                              <Box display='flex' alignItems='center'>
                                <IconButton onClick={() => setSelectedRole(null)}>
                                  <ArrowBackIcon />
                                </IconButton>
                                <Typography component='h4' fontWeight={500} fontSize={pxToRem(20)} letterSpacing='-0.022em' color='#636363'>
                                  {selectedRole?.name}
                                </Typography>
                              </Box>
                              <List>
                                {selectedRole?.permissions?.map((permission: any) => (
                                  <ListItem>{permission.description}</ListItem>
                                ))}
                              </List>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </StyledPaper>
                </Stack>
              </Box>
              <Box flex={1}>
                <Divider flexItem />
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Button
                    color='primary'
                    sx={{
                      '&:hover': {
                        background: theme.palette.primary.light,
                      },
                    }}
                    startIcon={<HelpIcon />}
                  >
                    How to
                  </Button>

                  <Typography>Last login: {user?.data?.last_login && format(new Date(user?.data?.last_login), 'eee, dd MMM yyyy')}</Typography>
                </Box>
              </Box>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2.5}>
            {data.map((item) => (
              <Grid key={item.slug} item xs={12} md={item.space}>
                <StyledPaper
                  elevation={0}
                  available={item.available}
                  onMouseEnter={() => setActiveCard(item.slug)}
                  onMouseLeave={() => setActiveCard('')}
                  onClick={() => item.available && navigate(item.path)}
                >
                  <Avatar sx={{ background: 'transparent' }}>
                    {item.icon({ active: false, color: item.available && item.slug === activeCard ? theme.palette.primary.main : '#636363' })}
                  </Avatar>
                  <Typography
                    component='h3'
                    fontWeight={500}
                    fontSize={pxToRem(20)}
                    lineHeight='38px'
                    textAlign='center'
                    letterSpacing='-0.022em'
                    color={item.available && item.slug === activeCard ? theme.palette.primary.main : '#636363'}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    fontWeight={200}
                    fontSize={pxToRem(16)}
                    width='70%'
                    lineHeight='16px'
                    textAlign='center'
                    letterSpacing='-0.022em'
                    color='#636363'
                    mb={pxToRem(24)}
                  >
                    {item.description}
                  </Typography>

                  {item.available && item.slug === activeCard && (
                    <Divider
                      flexItem
                      sx={{
                        position: 'absolute',
                        bottom: '3px',
                        left: '10px',
                        right: '10px',
                        height: '3px',
                        background: (theme) => theme.palette.primary.main,
                      }}
                    />
                  )}
                </StyledPaper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </ContentLayout>
  )
}

export default Home
