const PRIMARY = {
  light: 'rgba(253, 197, 197, 0.4)',
  main: '#CF2A2A',
  dark: '#DB3539',
}

const SUCCESS = {
  lighter: '#D4F7DC',
  light: '#EAFFE7',
  main: '#15692A',
}

const INFO = {
  lighter: '#F0F5FF',
  main: '#0050C8',
}

const WARNING = {
  light: '#EAFFE7',
  main: '#F9F2F2',
}

const ERROR = {
  lighter: '#FFD4D2',
  main: '#9F1F17',
}

const palette = {
  common: { black: '#000', white: '#fff', title: '#AAAAAA' },
  primary: PRIMARY,
  success: SUCCESS,
  info: INFO,
  error: ERROR,
  warning: WARNING,
  text: {
    primary: '#363636',
    secondary: '#636363',
    disabled: '#96989A',
    tertiary: '#747373',
    dark: '#212326',
    lightYellow: '#D5A62F',
    title: '#AAAAAA',
  },
  background: { paper: '#fff', default: '#F7F7F7', dark: '#EBE9E9', disabled: '#96989A', lightYellow: '#D5A62F' },
}

export default palette
