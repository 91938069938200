import { axiosBaseQuery } from '../config/axiosBaseQuery'
import { parseQueryParams } from '../utils/parseQueryParam'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IUpsertRole, IRolesResult, IRolesStatesult, IRole, IRequestsResult, IRequest, IActivityLogApiResponse } from './types'

export const roleApi = createApi({
    reducerPath: "roleApi",
    tagTypes: [
        'Roles',
        'Role',
        'RolesStat',
        'RolesRequests',
        'RolesRequestStat',
        'CheckRoleName',
        'RolesDownload',
        'RolesRequestsDownload',
        'RoleRequest',
        'ActivitiesLog'
    ],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        createRole: builder.mutation<void, IUpsertRole>({
            query: data => {
                return ({
                    url: "/api/v1/roles/requests/",
                    method: "post",
                    body: data
                })
            },
            invalidatesTags: ['RolesRequests', 'Roles', 'RolesStat'],
        }),
        checkPermissions: builder.mutation<void, { permissions: string[], role_id?: string }>({
            query: ({ role_id, ...data }) => {
                return ({
                    url: `/api/v1/roles/requests/check-permissions/?role_id=${role_id}`,
                    method: "post",
                    body: data
                })
            },
            invalidatesTags: ['RolesRequests'],
        }),
        activateRole: builder.mutation<void, { id: string | undefined }>({
            query: ({ id }) => {
                return ({
                    url: `/api/v1/roles/requests/reactivate/`,
                    method: "post",
                    body: { role: id }
                })
            },
            invalidatesTags: ['Roles', 'RolesStat'],
        }),
        deactivateRole: builder.mutation<void, { data: FormData }>({
            query: ({ data }) => {
                return ({
                    url: `/api/v1/roles/requests/deactivate/`,
                    method: "post",
                    body: data
                })
            },
            invalidatesTags: ['Roles', 'RolesStat'],
        }),
        approveRequest: builder.mutation<void, { request_id: string }>({
            query: ({ request_id }) => {
                return ({
                    url: `/api/v1/roles/requests/${request_id}/approve/`,
                    method: "post"
                })
            },
            invalidatesTags: ['Roles', 'RolesStat'],
        }),
        rejectRequest: builder.mutation<any, { request_id: string }>({
            query: ({ request_id, ...body }) => {
                return ({
                    url: `/api/v1/roles/requests/${request_id}/reject/`,
                    method: "post",
                    body
                })
            },
            invalidatesTags: ['RolesRequestStat', 'RolesRequests'],
        }),
        updateRole: builder.mutation<void, IUpsertRole>({
            query: (data) => {
                return ({
                    url: `/api/v1/roles/requests/modify/`,
                    method: "post",
                    body: data
                })
            },
            invalidatesTags: ['Roles', 'RolesStat'],
        }),
        modifyRequest: builder.mutation<void, any>({
            query: ({ request_id, ...data }) => {
                return ({
                    url: `/api/v1/roles/requests/${request_id}/`,
                    method: "put",
                    body: data
                })
            },
            invalidatesTags: ['RolesRequestStat', 'RolesRequests'],
        }),
        deleteRequest: builder.mutation<void, string>({
            query(id) {
                return {
                    url: `/api/v1/roles/requests/${id}`,
                    method: 'delete',
                }
            },
            invalidatesTags: ['RolesRequestStat', 'RolesRequests'],
        }),
        getRoles: builder.query<IRolesResult, { search?: string, status?: string, state?: string[], page?: number }>({
            query: (params) => {
                return ({
                    url: "/api/v1/roles",
                    method: "get",
                    config: {
                        params: parseQueryParams(params)
                    }
                })
            },
            providesTags: ['Roles'],
        }),
        getRolesRequests: builder.query<IRequestsResult, {
            search?: string,
            status?: string[],
            isActive?: boolean,
            page?: number,
            action_type?: string[]
        }>({
            query: (params) => {
                return ({
                    url: "/api/v1/roles/requests",
                    method: "get",
                    config: {
                        params: parseQueryParams(params)
                    }
                })
            },
            providesTags: ['RolesRequests'],
        }),
        getRole: builder.query<IRole, string>({
            query(id) {
                return {
                    url: `/api/v1/roles/${id}`,
                    method: 'get',
                }
            },
            providesTags: ['Role'],
        }),
        getRoleRequest: builder.query<IRequest, string>({
            query(id) {
                return {
                    url: `/api/v1/roles/requests/${id}`,
                    method: 'get',
                }
            },
            providesTags: ['RoleRequest'],
        }),
        downloadRoles: builder.query<any, { queryId: string, search?: string, status?: string, isActive?: boolean, page?: number }>({
            query(params) {
                return {
                    url: `/api/v1/roles/download`,
                    method: 'get',
                    config: {
                        params: parseQueryParams(params)
                    }
                }
            },
            keepUnusedDataFor: 1,
            providesTags: ['RolesDownload'],
        }),
        downloadRolesRequests: builder.query<any, { queryId: string, search?: string, status?: string, isActive?: boolean, page?: number }>({
            query(params) {
                return {
                    url: `/api/v1/roles/requests/download`,
                    method: 'get',
                    config: {
                        params: parseQueryParams(params)
                    }
                }
            },
            providesTags: ['RolesRequestsDownload'],
        }),
        checkRoleName: builder.query<IRolesResult, string>({
            query: (role_name) => ({
                url: `/api/v1/roles/requests/${role_name}/check-name/`,
                method: "get"
            }),
            providesTags: ['CheckRoleName'],
        }),
        getRolesState: builder.query<IRolesStatesult, void>({
            query() {
                return {
                    url: `api/v1/roles/stat`,
                    method: 'get',
                }
            },
            providesTags: ['RolesStat'],
        }),
        getRolesRequestStat: builder.query<IRolesStatesult, void>({
            query() {
                return {
                    url: `api/v1/roles/requests/stat`,
                    method: 'get',
                }
            },
            providesTags: ['RolesRequestStat'],
        }),
        getRoleActivitiesLog: builder.query<IActivityLogApiResponse, { role?: string, request?: string }>({
            query(params) {
                return {
                    url: `api/v1/roles/requests/logs`,
                    method: 'get',
                    config: {
                        params: parseQueryParams(params)
                    }
                }
            },
            providesTags: ['ActivitiesLog'],
        }),
    })
});

export const {
    useCreateRoleMutation,
    useGetRolesQuery,
    useUpdateRoleMutation,
    useGetRoleQuery,
    useGetRolesStateQuery,
    useActivateRoleMutation,
    useDeactivateRoleMutation,
    useGetRolesRequestsQuery,
    useGetRolesRequestStatQuery,
    useCheckRoleNameQuery,
    useCheckPermissionsMutation,
    useDownloadRolesQuery,
    useDownloadRolesRequestsQuery,
    useApproveRequestMutation,
    useGetRoleRequestQuery,
    useRejectRequestMutation,
    useModifyRequestMutation,
    useDeleteRequestMutation,
    useGetRoleActivitiesLogQuery,
} = roleApi
