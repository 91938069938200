import { IStyledListItemButtonProps } from './../../interfaces/sidebar.interface'
import { pxToRem } from '@app/theme/util'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { ISidebarIconBoxProps } from '@app/layouts/interfaces/sidebar.interface'

export const SideBarContainerStyle = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: pxToRem(100),
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '0px !important',
    height: '0px !important',
  },
}))

export const SideBarIconBoxStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ISidebarIconBoxProps>(({ theme, isActive }) => ({
  cursor: 'pointer',
  textAlign: 'center',
  padding: theme.spacing(1.3),
  borderRadius: '10px',
  transition: 'all .3s',

  '& > p': {
    // display: "none"
    opacity: isActive ? 1 : 0,
  },

  '&:hover': {
    background: '#d79c9c',
    borderRadius: '1px',
    '& > p': {
      opacity: 1,
    },
  },

  '&:focus': {
    background: '#fff',
  },
}))

export const StyledListItemIcon = styled(ListItemIcon)(() => ({ mr: 0, minWidth: 'unset', flex: 1, display: 'flex', alignItems: 'center' }))
export const StyledListItemText = styled(ListItemText)(() => ({ fontSize: pxToRem(10), textAlign: 'center', color: '#fff' }))

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'logo',
})<IStyledListItemButtonProps>(({ theme, isActive, logo }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // paddingBlock: pxToRem(24),
  paddingInline: pxToRem(14),
  background: isActive ? '#F7F7F7' : '',
  borderRadius: '10px',
  height: pxToRem(100),

  '&:hover': {
    background: logo ? 'inherit' : '#d79c9c',
    borderRadius: '1px',
  },
}))
