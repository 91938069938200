import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../config/axiosBaseQuery'
import { ILogin } from './types'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<void, ILogin>({
      query: (data) => {
        return {
          url: '/api/v1/auth/login/',
          method: 'post',
          body: data,
        }
      },
    }),
    forgotPassword: builder.mutation<void, { username?: string; token: string; email?: string }>({
      query: (data) => {
        return {
          url: '/api/v1/auth/password/initialise-password-reset/',
          method: 'post',
          body: data,
        }
      },
    }),
    resetPassword: builder.mutation<void, { old_password?: string; new_password: string }>({
      query: (data) => {
        return {
          url: '/api/v1/auth/change-password/',
          method: 'post',
          body: data,
        }
      },
    }),
  }),
})

export const { useLoginMutation, useForgotPasswordMutation, useResetPasswordMutation } = authApi
