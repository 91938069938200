import { createTheme } from '@mui/material/styles'

import './index.d'
import Table from './overrides/Table'
import Button from './overrides/Button'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import LoadingButton from './overrides/LoadingButton'
import Input from './overrides/Input'

const theme = createTheme({
  palette,
  typography,
  breakpoints,
})

theme.components = {
  ...Button(theme),
  ...Table(theme),
  ...LoadingButton(theme),
  ...Input(theme),
}

export default theme
