import { Theme } from '@mui/material/styles';

export default function MuiLoadingButton(theme: Theme) {
    return {
        MuiLoadingButton: {
            defaultProps: {
                disableTouchRipple: true
            },
            styleOverrides: {
                root: {
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#c65b5b'
                    }
                },
            }
        }
    };
}
