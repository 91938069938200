import { Theme, experimental_sx as sx } from '@mui/material/styles';

export default function Input(theme: Theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: theme.palette.text.disabled
                    },
                    '&::-ms-reveal, &::-ms-clear': {
                        display: "none"
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
              root: sx({
                
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                  },
              }),
            },
          },
    };
}
