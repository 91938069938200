import { axiosBaseQuery } from '../../config/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IGetAllRoles } from './rolesApi.types'
import { parseQueryParams } from '@app/utils/parseQueryParam'

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Roles'],
  endpoints: (builder) => ({
    getRoles: builder.query<IGetAllRoles, { page?: number; page_size?: number; search?: string }>({
      query: (params) => {
        return {
          url: '/api/v1/roles',
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
      providesTags: ['Roles'],
    }),
  }),
})

export const { useGetRolesQuery } = rolesApi
