const breakpoints = {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1720
    }
  };
  
  export default breakpoints;
  