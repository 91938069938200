import * as React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import { pxToRem } from '@app/theme/util'
import DashboardAppbar from '../components/DashboardHeader/DashboardAppbar'
import { useGetLoggedInUserQuery } from '@app/api/userApi/userApi'
import Sidebar from '../components/Sidebar/Sidebar'

const drawerWidth = pxToRem(110)

export interface IDashboardLayoutProps {
  children: React.ReactElement
}

export default function DashboardLayout({ children }: IDashboardLayoutProps) {
  const { data: user } = useGetLoggedInUserQuery()

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <AccessControlProvider permissions={[]}> */}
      <CssBaseline />
      <DashboardAppbar drawerWidth={drawerWidth} user={user} />
      <Sidebar drawerWidth={drawerWidth} />
      <Box component='main' sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
        <Toolbar sx={{ minHeight: '50px !important' }} />
        {children}
      </Box>
      {/* </AccessControlProvider> */}
    </Box>
  )
}
