export const configEnginePaths = {
  HOME: '/dashboard',
  CONFIG_ENGINE: '/configuration-engine',
  SOD_CONFIGURATION: '/configuration-engine/sod-configuration',
  SECURITY_CONFIGURATION: '/configuration-engine/security-configuration',
  CUSTOMER_MGT_FORM: '/configuration-engine/customer-management-form-configuration',
}

export const userAndAccessMgtPaths = {
  USER_AND_ACCESS_MANAGEMENT: '/user-and-access-management',
  USER_MANAGEMENT: '/user-and-access-management/user-management',
  ACCESS_MANAGEMENT: '/user-and-access-management/role-management',
  PREVIEW_USER: '/user-and-access-management/review-user',
  VIEW_USER: '/user-and-access-management/view-user',
}

export const productFactoryPaths = {
  PRODUCT_FACTORY: '/product-factory',
}

export const paymentPaths = {
  PAYMENT: '/payment',
}

export const customerMgtPaths = {
  CUSTOMER_MANAGEMENT: '/customer-management',
}

export const loanPaths = {
  LOAN: '/loan',
}

export const workflowPaths = {
  HOME: '/workflow',
}

export const reportingPaths = {
  HOME: '/reporting',
}

export const eodPaths = {
  HOME: '/eod-processing',
}
