import { Outlet } from 'react-router-dom'
// import AdminLayout from '../AdminLayout/views/AdminLayout'
import DashboardLayout from '@app/layouts/Dashboard/DashboardLayout'
import AuthGaurd from '@app/layouts/gaurds/AuthGaurd'

const ConfigEngineOutlet = () => {
  return (
    <AuthGaurd>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </AuthGaurd>
  )
}

export default ConfigEngineOutlet
