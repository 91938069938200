import { handleApiError } from '@app/utils/formatApiErrorMessage'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosError } from 'axios'
import toast from 'react-hot-toast'

import api from './api'

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method: 'get' | 'post' | 'patch' | 'delete' | 'put'
      body?: AxiosRequestConfig['data']
      config?: AxiosRequestConfig['params']
    },
    unknown,
    {
      status?: number
      errors?: any
      message?: any
      detail?: any
    }
  > =>
  async ({ url, method, body, config }) => {
    try {
      const result = await api[method](url, config, body)
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError<{ errors?: any; detail: any; message?: any }, any>

      const errorBody = {
        status: err.response?.status,
        errors: err.response?.data?.errors,
        message: err.response?.data?.message,
        detail: err.response?.data?.detail,
      }

      toast.error(handleApiError(err) || '')
      return { error: errorBody }
    }
  }
