import { useState } from 'react'
import { Box, styled, Typography, Grid } from '@mui/material'
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';

import { dashboardMenus } from '../component/DashboardItems'
import { IDashboardMenuChildren, IDashboardMenuItems } from '../interfaces/dashboardItems'
import { ReactComponent as RedDots } from '../../../../assets/svgs/RedDots.svg'
import ContentLayout from '@app/layouts/Content/ContentLayout'
import { StyledBareButton } from '@app/commonStyle';

const ConfigurationEngine = () => {
  const [data, setData] = useState<any[]>(dashboardMenus)
  const [showMore, setShowMore] = useState({ expand: false, active: '' })

  const handleGlobalSearch = (searchTerm: string) => {
    setData((prev) =>
      searchTerm
        ? prev.filter((items: IDashboardMenuItems) =>
          items.children.some((child) => child.subtitle?.toLowerCase().includes(searchTerm?.toLowerCase()))
        )
        : dashboardMenus
    )
  }

  const handleShowMore = (slug: string) => {
    setShowMore((prev) => ({
      ...prev,
      active: prev.active !== slug ? slug : '',
    }))
  };

  const handleClickAway = (slug: string) => {
    setShowMore((prev) => {
      const newState = {
        ...prev,
      };

      if (prev.active === slug) {
        newState.active = ""
      }
      return newState;
    })
  };

  const renderDashboardItem = (dashboardChild: IDashboardMenuChildren, index: number) => {
    return (
      <Grid item sm={4} mb={2} key={dashboardChild.subtitle + index}>
        <Link to={dashboardChild.path} style={{ textDecoration: "none" }}>
          <CardFlexItemStyle>
            <img src={dashboardChild.logo} alt={dashboardChild.subtitle} height='50' width='50' />
            <Typography color='text.disabled' variant='subtitle2' lineHeight='1rem'>
              {dashboardChild.subtitle}
            </Typography>
          </CardFlexItemStyle>
        </Link>
      </Grid>
    )
  }

  return (
    <ContentLayout
      title='CONFIGURATION ENGINE'
      size='sm'
      paperStyle={{ pb: 0 }}
      searchPlaceholder='Search for a configuration item'
      contentPosition='140px'
      onSearch={handleGlobalSearch}
    >
      <>
        <PageContainerStyle className='ho'>
          <CardContainerStyle className='he'>
            <Grid container spacing={2} sx={{ position: 'relative' }}>
              {data.length > 0 ? (
                data.map((dashboardItem: IDashboardMenuItems, index: number) => {
                  const isExpanded = showMore.active === dashboardItem.slug;

                  return (
                    <ClickAwayListener
                      key={dashboardItem.title + index}
                      onClickAway={() => handleClickAway(dashboardItem.slug)}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        height={250}
                      >
                        <Box sx={{ position: 'relative', height: '100%' }}>
                          <CardStyle
                            // @ts-ignore
                            isExpanded={isExpanded}
                          >
                            <Box height={60}>
                              <Typography color='text.secondary' variant='h6' textAlign='center' textTransform="uppercase">
                                {dashboardItem.title}
                              </Typography>
                            </Box>
                            <Grid container spacing={2}>
                              {dashboardItem.children.slice(0, 3).map(renderDashboardItem)}
                            </Grid>
                            <CollapsibleBoxStyle
                              // @ts-ignore
                              isExpanded={isExpanded}
                            >
                              <Grid container spacing={2}>
                                {dashboardItem.children.slice(3).map(renderDashboardItem)}
                              </Grid>
                            </CollapsibleBoxStyle>
                            {dashboardItem.children.length > 3 && (
                              <Box sx={{ float: "right" }}>
                                <StyledBareButton onClick={() => handleShowMore(dashboardItem.slug)} sx={{ p: 2, mr: -1, mb: -2 }}>
                                  <RedDots />
                                </StyledBareButton>
                              </Box>
                            )}
                          </CardStyle>
                        </Box>
                      </Grid>
                    </ClickAwayListener>
                  )
                })
              ) : (
                <Grid item xs={12}>
                  <Typography textAlign='center'>No item found</Typography>
                </Grid>
              )}
            </Grid>
          </CardContainerStyle>
        </PageContainerStyle>
      </>
    </ContentLayout>
  )
}

export default ConfigurationEngine

const PageContainerStyle = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: '8px',
  padding: theme.spacing(2),
  height: '74vh',
}))

const CardContainerStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  padding: theme.spacing(2),
}))

// @ts-ignore
const CardStyle = styled(Box)(({ theme, isExpanded }) => ({
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: theme.spacing(3),
  width: '100%',
  height: '100%',
  background: '#fff',
  ...(isExpanded && {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 16,
    height: 'auto',
  }),
}))

// @ts-ignore
const CollapsibleBoxStyle = styled(Box)(({ theme, isExpanded }) => ({
  overflowY: 'hidden',
  transitionProperty: 'height',
  transitionDuration: '.3s',
  height: isExpanded ? 100 : 0
}))

const CardFlexItemStyle = styled(Box)({
  textAlign: 'center'
})
