import { lazy } from 'react'
import { configEnginePaths, customerMgtPaths, loanPaths, paymentPaths, productFactoryPaths, userAndAccessMgtPaths } from './path'

const AdminRoutes = [
  {
    path: configEnginePaths.CONFIG_ENGINE,
    exact: true,
    component: lazy(() => import('../modules/ConfigurationEngine/ConfigEngineIndexPage/views/ConfigurationEngine')),
  },
  {
    path: configEnginePaths.SOD_CONFIGURATION,
    exact: true,
    component: lazy(() => import('../modules/ConfigurationEngine/AccessConfiguration/SegregationofDuties/views/SegregationOfDuties')),
  },
  {
    path: configEnginePaths.SECURITY_CONFIGURATION,
    exact: true,
    component: lazy(() => import('../modules/ConfigurationEngine/AccessConfiguration/SecurityConfiguration/SecurityConfiguration')),
  },
  {
    path: '/user-and-access-management/*',
    exact: true,
    component: lazy(() => import('../modules/UserAndAccessManagement/views/UserAndAccessManagement')),
  },
  {
    path: userAndAccessMgtPaths.PREVIEW_USER,
    exact: true,
    component: lazy(() => import('../modules/UserAndAccessManagement/views/UserManagement/ReviewUser')),
  },
  {
    path: userAndAccessMgtPaths.VIEW_USER,
    exact: true,
    component: lazy(() => import('../modules/UserAndAccessManagement/views/UserManagement/ViewUser')),
  },
  {
    path: productFactoryPaths.PRODUCT_FACTORY,
    exact: true,
    component: lazy(() => import('../modules/ProductFactory')),
  },
  {
    path: paymentPaths.PAYMENT,
    exact: true,
    component: lazy(() => import('../modules/Payment')),
  },
  {
    path: customerMgtPaths.CUSTOMER_MANAGEMENT,
    exact: true,
    component: lazy(() => import('../modules/CustomerMgt')),
  },
  {
    path: loanPaths.LOAN,
    exact: true,
    component: lazy(() => import('../modules/Loan')),
  },
]

export default AdminRoutes
