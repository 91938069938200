import { styled, Button } from '@mui/material'

export const StyledTableButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.secondary,
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
}))

export const StyledBareButton = styled(Button)(({ theme }) => ({
  padding: 0,
  backgroundColor: "none",
  margin: 0,
  minWidth: "auto",
  color: "inherit",
  textAlign: "left",
  "&:hover": {
    backgroundColor: "transparent",
    color: "inherit"
  }
}))
