import { axiosBaseQuery } from '../../config/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  IPostUser,
  IEnableDisableUser,
  IGetAllUsers,
  IUserStat,
  IGeneratePassword,
  IGetAllUserRequests,
  IUserRequestStat,
  IUserProfile,
  IGetSingleUserData,
  IGetSingleRequestData,
  IGetUserWithADArray,
} from './userApi.types'
import { parseQueryParams } from '@app/utils/parseQueryParam'
import { IActivityLogApiResponse } from '../types'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 0,
  tagTypes: [
    'Users',
    'UserRequests',
    'UserRequest',
    'User',
    'UsersStat',
    'UsersRequestsStat',
    'UsersDownload',
    'UsersRequestsDownload',
    'ActivitiesLog',
  ],
  endpoints: (builder) => ({
    getUsers: builder.query<IGetAllUsers, { search?: string; status?: string; page?: number; take?: number; state?: string[] }>({
      query: (params) => {
        return {
          url: `/api/v1/users`,
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
      providesTags: ['Users'],
    }),
    getUsersRequests: builder.query<IGetAllUserRequests, { search?: string; status?: string; page?: number; take?: number; state?: string[] }>({
      query: (params) => {
        return {
          url: `/api/v1/users/requests`,
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
      providesTags: ['UserRequests'],
    }),
    getUser: builder.query<IGetSingleUserData, string | null>({
      query(id) {
        return {
          url: `/api/v1/users/${id}/`,
          method: 'get',
          credentials: 'include',
        }
      },
      providesTags: ['User'],
    }),
    getUserRequest: builder.query<IGetSingleRequestData, string | null>({
      query(id) {
        return {
          url: `/api/v1/users/requests/${id}/`,
          method: 'get',
          credentials: 'include',
        }
      },
      providesTags: ['UserRequest'],
    }),
    addNewUser: builder.mutation<IPostUser, FormData>({
      query: (payload) => ({
        url: '/api/v1/users/requests/',
        method: 'post',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['UserRequests'],
    }),
    updateUser: builder.mutation<IPostUser, { user: FormData }>({
      query: ({ user }) => {
        return {
          url: `/api/v1/users/requests/modify/`,
          method: 'post',
          body: user,
        }
      },
      invalidatesTags: ['UserRequests', 'UserRequest'],
    }),
    withdrawRequest: builder.mutation<IPostUser, { id: string; payload: object }>({
      query: ({ id, payload }) => {
        return {
          url: `/api/v1/users/requests/${id}/`,
          method: 'put',
          body: payload,
        }
      },
      invalidatesTags: ['UserRequests', 'UserRequest', 'UsersRequestsStat', 'UsersStat'],
    }),
    updateRequest: builder.mutation<IPostUser, { id: string; user: FormData }>({
      query: ({ id, user }) => {
        return {
          url: `/api/v1/users/requests/${id}/`,
          method: 'put',
          body: user,
        }
      },
      invalidatesTags: ['UserRequests', 'UserRequest', 'Users', 'User'],
    }),
    activateUser: builder.mutation<IEnableDisableUser, { user: string }>({
      query: (payload) => ({
        url: `/api/v1/users/requests/reactivate/`,
        method: 'post',
        body: payload,
      }),
      invalidatesTags: ['UserRequests', 'UserRequest', 'UsersRequestsStat', 'Users', 'UsersStat', 'User'],
    }),
    deactivateUser: builder.mutation<IEnableDisableUser, FormData>({
      query: (payload) => ({
        url: `/api/v1/users/requests/deactivate/`,
        method: 'post',
        body: payload,
      }),
      invalidatesTags: ['UserRequests', 'UserRequest', 'UsersRequestsStat', 'Users', 'UsersStat', 'User'],
    }),
    getUsersStat: builder.query<IUserStat, void>({
      query() {
        return {
          url: `/api/v1/users/stats`,
          method: 'get',
        }
      },
      providesTags: ['UsersStat'],
    }),
    getUsersRequestsStat: builder.query<IUserRequestStat, void>({
      query() {
        return {
          url: `/api/v1/users/requests/stats/`,
          method: 'get',
        }
      },
      providesTags: ['UsersRequestsStat'],
    }),
    generateUserpassword: builder.query<IGeneratePassword, void>({
      query() {
        return {
          url: `/api/v1/users/generate-password/`,
          method: 'get',
        }
      },
    }),
    getLoggedInUser: builder.query<IUserProfile, void>({
      query() {
        return {
          url: `/api/v1/users/profile/`,
          method: 'get',
        }
      },
    }),
    getSecurityConfiguration: builder.query<any, void>({
      query() {
        return {
          url: `/api/v1/configs/general/`,
          method: 'get',
        }
      },
    }),
    approveUserCreation: builder.mutation<any, string | null>({
      query(id) {
        return {
          url: `/api/v1/users/requests/${id}/approve/`,
          method: 'post',
        }
      },
      invalidatesTags: ['UserRequests', 'User', 'Users', 'UserRequest', 'UsersStat', 'UsersRequestsStat'],
    }),
    rejectUserCreation: builder.mutation<any, { id: string | null }>({
      query({ id, ...body }) {
        return {
          url: `/api/v1/users/requests/${id}/reject/`,
          method: 'post',
          body,
        }
      },
      invalidatesTags: ['UserRequests', 'User', 'Users', 'UserRequest', 'UsersStat', 'UsersRequestsStat'],
    }),
    downloadUsers: builder.query<any, { queryId: string; search?: string; status?: string; isActive?: boolean; page?: number }>({
      query(params) {
        return {
          url: `/api/v1/users/download`,
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
      keepUnusedDataFor: 1,
      providesTags: ['UsersDownload'],
    }),
    downloadUserRequests: builder.query<any, { queryId: string; search?: string; status?: string; isActive?: boolean; page?: number }>({
      query(params) {
        return {
          url: `/api/v1/users/requests/download/`,
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
      providesTags: ['UsersRequestsDownload'],
    }),
    deleteUserRequest: builder.mutation<any, string>({
      query: (id) => ({
        url: `/api/v1/users/requests/${id}/`,
        method: 'delete',
      }),
      invalidatesTags: ['UserRequests', 'UsersRequestsStat'],
    }),
    getUserActivitiesLog: builder.query<IActivityLogApiResponse, { user?: string | null; request?: string | null }>({
      query(params) {
        return {
          url: `api/v1/users/logs`,
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
      providesTags: ['ActivitiesLog'],
    }),
    getUserWithAD: builder.query<IGetUserWithADArray, { search?: string }>({
      query(params) {
        return {
          url: `/api/v1/users/ad/username/search/`,
          method: 'get',
          config: {
            params: parseQueryParams(params),
          },
        }
      },
    }),
  }),
})

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetUserRequestQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetUsersStatQuery,
  useGenerateUserpasswordQuery,
  useGetLoggedInUserQuery,
  useGetSecurityConfigurationQuery,
  useGetUsersRequestsQuery,
  useGetUsersRequestsStatQuery,
  useApproveUserCreationMutation,
  useRejectUserCreationMutation,
  useDownloadUserRequestsQuery,
  useDownloadUsersQuery,
  useDeleteUserRequestMutation,
  useUpdateRequestMutation,
  useGetUserActivitiesLogQuery,
  useWithdrawRequestMutation,
  useGetUserWithADQuery,
} = userApi
