import { lazy } from 'react'

export const DashboardRoutes = [
  {
    path: '/dashboard',
    exact: true,
    component: lazy(() => import('../modules/Home/Home')),
    children: [],
  },
]
