import axios, { AxiosPromise, Cancel } from 'axios'
import { ApiRequestConfig, WithAbortFn, ApiExecutor, ApiExecutorArgs, ApiError } from './api.types'

import { BASE_API_URL } from '../constants/api'
import Auth from '../utils/auth'

// Default config for the axios instance
const axiosParams = {
  // Set different base URL based on the environment
  baseURL: BASE_API_URL,
}

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams)

axiosInstance.interceptors.request.use(
  (config: any) => {
    // This adds an authorization key to config object if a token exists.
    if (Auth.tokenActive()) {
      config.headers.common['Authorization'] = `Bearer ${Auth.getToken()}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

const didAbort = (error: unknown): error is Cancel & { aborted: boolean } => axios.isCancel(error)

const getCancelSource = () => axios.CancelToken.source()

export const isApiError = (error: unknown): error is ApiError => axios.isAxiosError(error)

const withAbort = <T>(fn: WithAbortFn) => {
  const executor: ApiExecutor<T> = async (...args: ApiExecutorArgs) => {
    const originalConfig = args[args.length - 1] as ApiRequestConfig
    // Extract abort property from the config
    const { abort, ...config } = originalConfig

    // Create cancel token and abort method only if abort
    // function was passed
    if (typeof abort === 'function') {
      const { cancel, token } = getCancelSource()
      config.cancelToken = token
      abort(cancel)
    }

    try {
      if (args.length > 2) {
        const [url, body] = args
        return await fn<T>(url, body, config)
      } else {
        const [url] = args
        return await fn<T>(url, config)
      }
    } catch (error) {
      // Add "aborted" property to the error if the request was cancelled
      if (didAbort(error)) {
        error.aborted = true
      }

      throw error
    }
  }

  return executor
}

const withLogger = async <T>(promise: AxiosPromise<T>) =>
  promise.catch((error: ApiError) => {
    /*
    Always log errors in dev environment
    if (process.env.NODE_ENV !== 'development') throw error      
  */
    // Log error only if VUE_APP_DEBUG_API env is set to true
    if (!process.env.REACT_APP_DEBUG_API) throw error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      // in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)

    throw error
  })

// Main api function
const api = {
  get: <T>(url: string, config: ApiRequestConfig = {}) => withLogger<T>(withAbort<T>(axiosInstance.get)(url, config)),
  delete: <T>(url: string, config: ApiRequestConfig = {}) => withLogger<T>(withAbort<T>(axiosInstance.delete)(url, config)),
  post: <T>(url: string, config: ApiRequestConfig = {}, body: unknown) => withLogger<T>(withAbort<T>(axiosInstance.post)(url, body, config)),
  patch: <T>(url: string, config: ApiRequestConfig = {}, body: unknown) => withLogger<T>(withAbort<T>(axiosInstance.patch)(url, body, config)),
  put: <T>(url: string, config: ApiRequestConfig = {}, body: unknown) => withLogger<T>(withAbort<T>(axiosInstance.put)(url, body, config)),
}

export default api
